import {App, Component, createApp } from "vue";
import {createPinia} from "pinia";
import {AnyType} from "@/ts/components/Types/AnyType";

// TODO: move this stuff into Vue.Js composables

/**
 * Handles all Vue related stuff.
 */
export default class FrontendVueHandler {

    /**
     * Registers vue components on page load.
     */
    static async initPageVue(): Promise<void> {
        // registers components with id^="etvue_"
        await this.autoRegisterVueElements();
    }

    /**
     * Register Vue Elements.
     * @param selector
     * @param vueElement
     * @param rootProps
     */
    static registerVueElement(selector: string, vueElement: Component, rootProps: { [key: string]: AnyType } = {}): void {
        const divElements: NodeListOf<HTMLDivElement> = document.querySelectorAll(selector);
        const vueApp: { [key: string]: App } = {};
        divElements.forEach((element: HTMLDivElement): void => {
            if (!element.getAttribute("data-vue-mounted")) {
                vueApp[element.id] = createApp(vueElement, {
                    ...rootProps,
                    elementId: element.id
                });
                vueApp[element.id]?.use(createPinia());
                vueApp[element.id]?.mount(element);
                vueApp[element.id]?.directive("click-outside", {
                    beforeMount(el, binding) {
                        el.clickOutsideEvent = (evt) => {
                            evt.stopPropagation();
                            if (!(el === evt.target || el.contains(evt.target))) {
                                binding.value(evt, el);
                            }
                        };
                        window.requestAnimationFrame(() => {
                            document.addEventListener("click", el.clickOutsideEvent);
                        });
                    },
                    unmounted(el) {
                        document.removeEventListener("click", el.clickOutsideEvent);
                    },
                });
                element.setAttribute("data-vue-mounted", "true");
            }
        });
    }

    /**
     * Registers all vue elements with id "etvue_VUE_COMPONENT_NAME_*" as vue component.
     */
    public static async autoRegisterVueElements(): Promise<void> {
        const elements: NodeListOf<Element> = document.querySelectorAll("[id^='etvue_']");
        const vueElements: string[] = Array.from(elements)
            .map((element: Element) => element.id.split("_")[1])
            .filter((vueElementName: string) => vueElementName);

        for (const vueElementName of vueElements) {
            try {
                const loadedModule = await import(`../../../components/element/${vueElementName}.vue`);
                const vueElement = loadedModule.default;
                this.registerVueElement(
                    `[id^="etvue_${vueElementName}"]`,
                    vueElement,
                    {}
                );
            } catch (error) {
                console.error(`Error importing Vue component ${vueElementName}:`, error);
            }
        }
    }
}
