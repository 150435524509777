import EtPersonalJson from "@/ts/components/EtPersonalJson";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import ApiHandler from "@/ts/components/Handlers/ApiHandler";

/**
 * Provides functionality for adding articles to the shopping cart.
 */
export default class Warenkorb {
    private etPersonalJson: EtPersonalJson;
    private readonly hasVariables: boolean;

    constructor(etPersonalJson: EtPersonalJson) {
        this.etPersonalJson = etPersonalJson;
        this.hasVariables = window.etData.vwType === "warenkorb";
        this.addA2CEventListener()
    }

    /**
     * Adds an article to the shopping cart.
     *
     * @param url - The URL of the article.
     * @param menge0_selector - The selector for the quantity input element (optional) otherwise 1.
     * @param artikelId - The ID of the article.
     * @param lpvbs (optional)
     * @param menge
     */
    async addArtikel(url: string, menge0_selector: string|undefined, artikelId: string, lpvbs = "", menge = "1"): Promise<any> {
        if (menge.toString().includes(",")) {
            menge = menge.toString().replace(",", ".");
        }

        await ApiHandler.getJsonFetch(
            {
                service: "getViewContent",
                url: encodeURI(url.replace(/&amp;/g, "&")),
                praesenz: window.etData.presenceId.toString(),
            },
            {
                menge0: menge,
                ac_id0: artikelId,
                add0: artikelId,
                lpvbs: lpvbs,
            });

        if (window.trackPeeriusCart && typeof window.trackPeeriusCart === "function") {
            window.trackPeeriusCart(artikelId, menge);
        }

        return EtPersonalJson.getInstance().fetchData(false);
    }

    async addA2CEventListener() {
        document.querySelectorAll(".element_artikel_warenkorb__a2c").forEach((button) => {
            if (!(button instanceof HTMLButtonElement) || button.dataset.addA2CEventListener === 'done') {
                return;
            }
            button.dataset.addA2CEventListener = 'done';
            button.addEventListener("click", async () => {
                const container: HTMLElement | null = button.closest(".element_artikel_warenkorb");
                if (!container) {
                    throw new Error("No container found for a2c button");
                }
                const artikelId = Number(button.getAttribute("data-artikel-id") || 0);
                const buyUrl = button.getAttribute("data-buy-url") || "";
                let menge = 1;
                if (button.dataset.getMengeFrom) {
                    let mengeElement = document.getElementById(button.dataset.getMengeFrom);
                    if (!mengeElement) {
                        mengeElement = container.querySelector('.' + button.dataset.getMengeFrom);
                    }
                    if (mengeElement instanceof HTMLInputElement) {
                        menge = Number(mengeElement.value) || 1;
                    }
                }
                const lpVbs = button.dataset.lpvbs || "";
                if (artikelId > 0 && menge > 0 && buyUrl) {
                    await FrontendHandler.blockSubmitAndShowLoading(
                        async () => {
                            await this.addArtikel(buyUrl, undefined, artikelId.toString(), lpVbs, menge.toString()).then(openCartPreview);
                        },
                        container,
                        button
                    );
                    let jsonString = button.dataset.gtm;
                    if (!jsonString) {
                        let productElement = document.getElementById('product' + artikelId);
                        if (productElement && productElement.dataset.gtm) {
                            jsonString = productElement.dataset.gtm;
                        }
                    }
                    if (jsonString) {
                    let gtmData = JSON.parse(jsonString);
                        if (dataLayer && gtmData) {
                            gtmData.ecommerce.add.products.quantity = menge;
                            dataLayer.push(gtmData);
                        }
                    }
                }
            });
        });
    }
}

export function openCartPreview() {
    document.querySelectorAll("#layout_header_button_cart").forEach((element) => {
        if (element instanceof HTMLButtonElement && element.getAttribute("aria-expanded") == "false") {
            element.click();
        }
    })
}
