import type Template from "@/ts/components/Types/Template";
import PiniaStoreManager from "@/ts/components/PiniaStoreManager";
import {AnyType} from "@/ts/components/Types/AnyType";
import ApiHandler from "@/ts/components/Handlers/ApiHandler";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";

export default class VariableCollector {
    private _dataTemplates: Template[] = [];
    private piniaStoreManager: PiniaStoreManager;

    constructor(piniaStoreManager?: PiniaStoreManager) {
        this.piniaStoreManager = piniaStoreManager || FrontendHandler.getPublicVar<PiniaStoreManager>("piniaStoreManager");
    }

    // registers variables
    public async registerVariables(
        vwType: string,
        vwName: string,
        params: { [name: string]: AnyType } = {},
        vwId = 0,
        customFilename?: string,
        firstRun = false,
        getParams: { [key: string]: string } = {},
        postParams: { [key: string]: string } = {}
    ): Promise<void> {
        let filename = `json_variable_collector_${vwType}_${vwName}`;

        if(customFilename){
            filename = customFilename;
        }

        const template = {
            vwType: vwType,
            vwName: vwName,
            params: params,
            vwId: vwId,
            path: `json_variable_collector/${filename}`,
            name: filename,
        };

        if(firstRun){
            await this.initPiniaStoreVariables(template, true);
            this.dataTemplates.push(template);
        }else{
            await this.updateTemplate(template, getParams, postParams).then((jsonData) => {
                this.piniaStoreManager.autoSetVariables(jsonData);
                this.dataTemplates.push(template);
            });
        }
    }

    /**
     * Updates a template.
     *
     * @param {Template} dataTemplate - The template to update.
     * @param {Object} getParams - Optional parameters for GET requests.
     * @param {Object} postParams - Optional parameters for POST requests.
     * @return {Promise<any>} A promise that resolves with the updated template data.
     */
    public async updateTemplate(
        dataTemplate: Template,
        getParams: { [key: string]: string } = {},
        postParams: { [key: string]: string } = {}
    ): Promise<AnyType> {
        return await ApiHandler.getJsonDataFromTemplate(dataTemplate, getParams, postParams);
    }

    /**
     * Initializes the Pinia store variables.
     *
     * @param {Template} template - The template to initialize the variables.
     * @param {boolean} firstRun - Whether it is the first run.
     */
    public async initPiniaStoreVariables(
        template: Template,
        firstRun = false
    ) {
        let jsonData = window[template.name] || [];
        if (jsonData.length === 0 || !firstRun) {
            try {
                jsonData = await this.updateTemplate(template);
            } catch (e) { /* empty */ }
        }
        this.piniaStoreManager.autoSetVariables(jsonData);
    }

    /**
     * Updates all templates with variables.
     *
     * @return {Promise<void>} A promise that resolves when all variables are updated.
     */
    public async updateAllVariables(): Promise<void> {
        for (const template of this.dataTemplates) {
            await this.updateTemplate(template).then((jsonData) => {
                this.piniaStoreManager.autoSetVariables(jsonData);
            });
        }
    }

    get dataTemplates(): Template[] {
        return this._dataTemplates;
    }

    set dataTemplates(value: Template[]) {
        this._dataTemplates = value;
    }
}
