import EtailerFrontend from "@/ts/components/EtailerFrontend";

export default class SuperHeader {
    etailerFrontend: EtailerFrontend;
    superHeaderElement: HTMLElement|null;

    constructor(superHeaderElement?: HTMLElement, etailerFrontend?: EtailerFrontend) {
        this.etailerFrontend = etailerFrontend || new EtailerFrontend();
        this.superHeaderElement = superHeaderElement || document.getElementById("superheader");
        if(this.superHeaderElement) {
            this.initSuperHeader();
        }
    }

    initSuperHeader() {
        if (!this.etailerFrontend.getCookie("hideSuper")) {
            this.superHeaderElement?.classList.remove("d-none");
            const superHeaderBtn = document.getElementById("superheader-close");
            if (superHeaderBtn) {
                superHeaderBtn.addEventListener("click", ()=> {
                    this.etailerFrontend.setCookie("hideSuper", 1, 1);
                    document.getElementById("superheader")?.classList.add("d-none");
                });
            }
        }
    }
}
