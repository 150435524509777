import Modal from "bootstrap/js/dist/modal";

export function olk_ShowHTML(windowName: string, anchorElement: HTMLAnchorElement, options: string): boolean {
    const s = anchorElement.href;
    const anker = s.split("#");
    let url = anker[0];

    const n = url.indexOf("?");
    if (n > -1) {
        url = url + "&nohead=1";
    } else {
        url = url + "?nohead=1";
    }
    if (anker.length >= 2) {
        url += "#" + anker[anker.length - 1];
    }

    if (!window.etData.modalMap) {
        window.etData.modalMap = new Map();
    }

    if (window.etData.modalMap.has(url)) {
        const modal = window.etData.modalMap.get(url);
        if (modal) {
            modal.show();
            return false;
        }
    }

    showSpinner();

    const fetchOptions: RequestInit = {
        method: "GET",
        credentials: "same-origin",
        headers: {
            "Content-Type": "text/html"
        }
    };

    fetch(url, fetchOptions)
        .then(response => response.text())
        .then(data => {
            const modal = createModalHtml(anchorElement.innerHTML, data);

            const template = document.createElement("template");
            template.innerHTML = modal.html;
            document.body.appendChild(template.content);

            const modalApi = new Modal("#" + modal.id, {backdrop: true});
            window.etData.modalMap.set(url, modalApi);

            modalApi.show();
        })
        .catch(error => {
            console.error("Error:", error);
        })
        .finally(() => {
            hideSpinner();
        });

    return false;
}

export function createModalHtml(titleHtml: string, contentHtml: string) {
    const id = "modal_" + (performance?.now() || Date.now());
    const html = `
    <div class="modal fade" data-bs-backdrop="static" tabindex="-1" id="${id}" aria-labelledby="${id}_title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="${id}_title">${titleHtml}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">${contentHtml}</div>
            </div>
        </div>
    </div>
    `;

    return {id, html};
}
