import FunctionHandler from "@/ts/components/Handlers/FunctionHandler";
import {BootstrapViewportEnum} from "@/ts/components/Enums/BootstrapViewportEnum";
import StaticClass from "@/ts/components/StaticClass";

/**
 * Funktionssammlung für Window-Verarbeitung.
 */
export default class WindowHandler extends StaticClass {
    private static viewportDimensions: { width: number; height: number } = {width: 0, height: 0};
    private static scrollTop?: number;
    private static window: Window = window;
    private static document: Document = document;

    /**
     * For testing purposes only.
     * @param window
     */
    public static setWindow(window: Window): void {
        this.window = window;
    }

    /**
     * For testing purposes only.
     * @param document
     */
    public static setDocument(document: Document): void {
        this.document = document;
    }

    /**
     * @returns The viewport width.
     */
    public static getViewportWidth(): number {
        return this.getViewportDimensions().width;
    }

    /**
     * @returns The viewport height.
     */
    public static getViewportHeight(): number {
        return this.getViewportDimensions().height;
    }

    /**
     * Updates the dimensions of the viewport.
     */
    public static updateViewportDimensions(): void {
        this.viewportDimensions.width = Math.max(
            this.document.documentElement.clientWidth || 0,
            this.window.innerWidth || 0
        );
        this.viewportDimensions.height = Math.max(
            this.document.documentElement.clientHeight || 0,
            this.window.innerHeight || 0
        );
        // console.debug('updateViewportDimensions', {d:WindowHandler.viewportDimensions});
    }

    /**
     * Returns the dimensions of the viewport.
     * @returns The viewport dimensions.
     */
    public static getViewportDimensions(): { width: number; height: number } {
        if (this.viewportDimensions.width === 0) {
            this.updateViewportDimensions();
        }
        return this.viewportDimensions;
    }

    /**
     * Updates the scroll top value of the document.
     * @param reset - Optional. If true, the scroll top value will be reset to null.
     */
    public static updateDocumentScrollTop(reset?: boolean): void {
        this.scrollTop = (reset === true) ? 0 : this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    }

    /**
     * Retrieves the scroll top value of the document.
     *
     * @returns The scroll top value.
     */
    public static getScrollTop(): number {
        if (typeof this.scrollTop !== "number") {
            this.updateDocumentScrollTop();
        }
        return this.scrollTop || 0;
    }

    /**
     * Apply the appropriate classes based on the current viewport.
     */
    public static applyViewportClasses(): void {
        // Find all elements with data-toggle-* attributes
        const elements: NodeListOf<Element> = this.document.querySelectorAll("[data-toggle-desktop], [data-toggle-mobile]");

        elements.forEach((element: Element): void => {
            const desktopClasses: string[] | undefined = element.getAttribute("data-toggle-desktop")?.split(" ");
            const mobileClasses: string[] | undefined = element.getAttribute("data-toggle-mobile")?.split(" ");

            // If the current viewport is XL or larger, apply the desktop classes
            if (this.getCurrentBootstrapViewport() >= BootstrapViewportEnum.LG_MIN && desktopClasses) {
                if (mobileClasses) element.classList.remove(...mobileClasses);  // remove mobile classes
                element.classList.add(...desktopClasses); // add desktop classes
            }
            // Otherwise, apply the mobile classes
            else if (mobileClasses) {
                if (desktopClasses) element.classList.remove(...desktopClasses);  // remove desktop classes
                element.classList.add(...mobileClasses);  // add mobile classes
            }
        });
    }

    /**
     * Returns the current minimum bootstrap viewport.
     */
    public static getCurrentBootstrapViewport(): BootstrapViewportEnum {
        const windowWidth: number = this.getViewportWidth();
        const viewports: (BootstrapViewportEnum.XXL_MIN | BootstrapViewportEnum.XL_MIN | BootstrapViewportEnum.LG_MIN | BootstrapViewportEnum.MD_MIN | BootstrapViewportEnum.SM_MIN | BootstrapViewportEnum.XS_MIN)[] = [
            BootstrapViewportEnum.XXL_MIN,
            BootstrapViewportEnum.XL_MIN,
            BootstrapViewportEnum.LG_MIN,
            BootstrapViewportEnum.MD_MIN,
            BootstrapViewportEnum.SM_MIN,
            BootstrapViewportEnum.XS_MIN
        ];
        for (const viewport of viewports) {
            if (windowWidth >= viewport) {
                return viewport;
            }
        }
        return BootstrapViewportEnum.XS_MIN;
    }

    /**
     * Only executes the function
     * @param previousViewportWidth
     * @param func
     */
    public static handleResize(previousViewportWidth: number, func: () => void): number {
        const currentViewportWidth: number = this.getViewportWidth();
        if (currentViewportWidth !== previousViewportWidth) {
            func();
            return currentViewportWidth ?? previousViewportWidth;
        }
        return previousViewportWidth;
    }

    /**
     * Handles the given viewport actions on resize.
     * @param viewportAction
     */
    public static viewportHandler(viewportAction: Array<{
        viewport: BootstrapViewportEnum,
        funct: () => void
    }> = []): void {
        this.window.addEventListener("resize", FunctionHandler.debounce(() => {
            const currentViewport: BootstrapViewportEnum = this.getCurrentBootstrapViewport();
            for (const func of viewportAction) {
                if (func.viewport === currentViewport) {
                    func.funct();
                }
            }
        }, 250));
    }

    /**
     * Retrieves the current viewport type and name.
     * @returns {Object} An object containing the current viewport type and name.
     *                   - type: The current viewport type.
     *                   - name: The current viewport name.
     */
    public static getCurrentVwTypeName(): { type: string, name: string } {
        const vwType: string = String(this.window.etData?.vwType || "").toLowerCase();
        const vwName: string = String(this.window.etData?.vwName || "").toLowerCase();
        return {type: vwType, name: vwName};
    }

    /**
     * Retrieves the minimum value of the specified breakpoint name.
     * @relates window
     *
     * @param {string} name - The name of the breakpoint.
     * @param {any} [fallback=null] - An optional fallback value to return if the breakpoint is not found.
     * @returns {null|number} - The minimum value of the breakpoint, or the fallback value if not found.
     */
    public static getBreakpointMin = (name: string, fallback: number | null = null): null | number => {
        const layoutBreakpoints = this.window.etData?.layoutBreakpoints || null;
        if (layoutBreakpoints && typeof layoutBreakpoints === "object" && name in layoutBreakpoints) {
            const breakpoint = layoutBreakpoints[name];
            if (breakpoint && typeof breakpoint === "object" && "min" in breakpoint) {
                return breakpoint.min;
            }
        }
        return fallback;
    };

    /**
     * Calculates and logs the distance between an element and the bottom of the viewport.
     *
     * @param element Optional. The target element to measure the distance from.
     * If not provided, it prompts the user to enter a file name to search for the element in the document.
     */
    public static getDistanceToViewportBottom(element?: HTMLElement): void {
        if (!element) {
            const fileName: string | null = prompt("Enter the file name:");
            if (fileName?.length) {
                const selectors: string[] = [
                    "[src*=\"" + fileName + "\"]",
                    "[srcset*=\"" + fileName + "\"]",
                    "[href*=\"" + fileName + "\"]"
                ];
                element = selectors
                    .map((selector: string) => this.document.querySelector(selector))
                    .find((el: Element | null): boolean => el instanceof HTMLElement) as HTMLElement;
            }
        }

        if (element instanceof HTMLElement) {
            const scrollPosition: number = this.window.scrollY;
            const elementTop: number = scrollPosition + element.getBoundingClientRect().top;
            const viewportHeight: number = this.window.innerHeight;
            const viewportBottom: number = scrollPosition + viewportHeight;

            /*console.debug(element, {
                d: elementTop - viewportBottom,
                et: elementTop,
                vh: viewportHeight,
                vb: viewportBottom
            });*/
        }
    }

    /**
     * TODO: An etDev auslagern
     *
     * Measures and logs various timings related to the Critical Rendering Path (CRP) of a web page.
     * Provides insights into the different stages of the page loading and rendering process.
     */
    public static measureCRP(): void {
        if ("performance" in this.window && "getEntriesByType" in this.window.performance) {
            const entries: PerformanceEntry[] = this.window.performance.getEntriesByType("navigation");
            if (entries.length > 0) {
                const navigationEntry: PerformanceNavigationTiming = entries[0] as PerformanceNavigationTiming;
                const {
                    domContentLoadedEventStart,
                    domContentLoadedEventEnd,
                    loadEventStart,
                    loadEventEnd
                }: PerformanceNavigationTiming = navigationEntry;

                // CRP marks
                const crpMarks = {
                    "interactive": domContentLoadedEventStart,
                    "dcl": domContentLoadedEventEnd - domContentLoadedEventStart,
                    "complete": loadEventStart,
                    "total": loadEventEnd
                };

                // CRP time
                const crpTime: { load: number; dcl: number; loading: number } = {
                    "dcl": domContentLoadedEventEnd - domContentLoadedEventStart,
                    "loading": loadEventStart - domContentLoadedEventEnd,
                    "load": loadEventEnd - loadEventStart
                };

                console.log("CRP marks", crpMarks);
                console.log("CRP time", crpTime);
            }
        }
    }
}
