import {SwiperOptions} from "swiper/types/swiper-options";
import {BootstrapViewportEnum} from "./Enums/BootstrapViewportEnum";

export default class EtSliderBreakpointBuilder {
    private readonly config: SwiperOptions;
    private defaultOptions?: SwiperOptions;
    private sm?: SwiperOptions;
    private md?: SwiperOptions;
    private lg?: SwiperOptions;
    private xl?: SwiperOptions;
    private xxl?: SwiperOptions;

    public constructor(config: SwiperOptions) {
        this.config = config;
    }

    public setDefault(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.defaultOptions = config;
        return this;
    }

    public setSm(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.sm = config;
        return this;
    }

    public setMd(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.md = config;
        return this;
    }

    public setLg(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.lg = config;
        return this;
    }

    public setXl(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.xl = config;
        return this;
    }

    public setXxl(config: SwiperOptions): EtSliderBreakpointBuilder {
        this.xxl = config;
        return this;
    }

    public build(): SwiperOptions {
        let config = {...this.config};
        if (this.defaultOptions) {
            config = {...config, ...this.defaultOptions};
        }

        if (!config?.breakpoints) {
            config.breakpoints = {};
        }

        if (this.sm) {
            const breakpointWidth = BootstrapViewportEnum.SM_MIN;
            const breakpointConfig = config.breakpoints?.[breakpointWidth] || {};
            config.breakpoints[breakpointWidth] = {...breakpointConfig, ...this.sm};
        }

        if (this.md) {
            const breakpointWidth = BootstrapViewportEnum.MD_MIN;
            const breakpointConfig = config.breakpoints?.[breakpointWidth] || {};
            config.breakpoints[breakpointWidth] = {...breakpointConfig, ...this.md};
        }

        if (this.lg) {
            const breakpointWidth = BootstrapViewportEnum.LG_MIN;
            const breakpointConfig = config.breakpoints?.[breakpointWidth] || {};
            config.breakpoints[breakpointWidth] = {...breakpointConfig, ...this.lg};
        }

        if (this.xl) {
            const breakpointWidth = BootstrapViewportEnum.XL_MIN;
            const breakpointConfig = config.breakpoints?.[breakpointWidth] || {};
            config.breakpoints[breakpointWidth] = {...breakpointConfig, ...this.xl};
        }

        if (this.xxl) {
            const breakpointWidth = BootstrapViewportEnum.XXL_MIN;
            const breakpointConfig = config.breakpoints?.[breakpointWidth] || {};
            config.breakpoints[breakpointWidth] = {...breakpointConfig, ...this.xxl};
        }

        this.resetObject();

        return config;
    }

    protected resetObject(): void {
        this.defaultOptions = undefined;
        this.sm = undefined;
        this.md = undefined;
        this.lg = undefined;
        this.xl = undefined;
        this.xxl = undefined;
    }
}
