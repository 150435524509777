import ApiHandler from "@/ts/components/Handlers/ApiHandler";

export default class FormValidation {

    constructor(formElement?: HTMLFormElement) {
        if(formElement){
            this.initDefaultInlineValidate(formElement);
        } else {
            document.querySelectorAll("form").forEach((formElement: HTMLFormElement) => {
                this.initDefaultInlineValidate(formElement);
            });
        }
    }

    public async validatePassword(password: string, email: string) {
        await ApiHandler.getJsonResponse({
            service: "validatePassword",
            password: password,
            email: email
        });
        return Promise.resolve();
    }

    public validateOneInput(input: HTMLInputElement, formElement: HTMLFormElement) {
        const value = input.value;
        const min = input.dataset.minlen ?? 0;
        const parentDiv = input.parentElement;
        if (parseFloat(min.toString()) > 0 && parentDiv) {
            let isValid: boolean;
            if (input.type == "email") {
                const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.([a-z]+){2,}$/i;
                isValid = regex.test(value);
            } else if (input.type == "password") {
                // TODO password validation by ajax if new rules are in branch
                isValid = (value.length >= parseFloat(min.toString()));
                const validation = input.dataset.validate ?? "";
                if (validation == "password-repeat") {
                    const repeatFrom = input.dataset.repeatFrom ?? "";
                    if (repeatFrom != "") {
                        const compareInput = formElement.querySelector(`#${repeatFrom}`) as HTMLInputElement;
                        isValid = (input.value == compareInput.value);
                    } else {
                        console.error("Missing data-repeat-from");
                    }
                }
            } else {
                isValid = (value.length >= parseFloat(min.toString()));
            }

            if (isValid) {
                // this is good
                input.classList.add("is-valid");
                parentDiv.classList.remove("has-error");
            } else {
                // this is bad
                input.classList.remove("is-valid");
                parentDiv.classList.add("has-error");
            }
        }
    }

    public initDefaultInlineValidate(formElement: HTMLFormElement) {
        Array.from(formElement.getElementsByClassName("inline_validate")).forEach((dataElement: Element) => {
            if (dataElement instanceof HTMLInputElement) {
                dataElement.addEventListener("blur", () => {
                    this.validateOneInput(dataElement, formElement);
                });
            }
        });
    }
}
