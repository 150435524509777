import { defineStore } from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";
import type Merkliste from "@/ts/components/Types/Merkliste";

export const useMerklisteStore = defineStore("merklisteStore", () => {
    const merkliste: Ref<Merkliste> = ref({} as Merkliste);
    const getMerkliste: ComputedRef<Merkliste> = computed(() => {
        return merkliste.value;
    });
    const setMerkliste: (newMerkliste: Merkliste) => void = (newMerkliste: Merkliste) => {
        merkliste.value = newMerkliste;
    };
    return { getMerkliste, setMerkliste };
});
