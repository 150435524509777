import {createPinia, Pinia} from "pinia";
import type Artikel from "@/ts/components/Types/Artikel";
import {useTranslationStore} from "@/ts/components/PiniaStores/TranslationStore";
import Translation from "@/ts/components/Types/Translation";
import EtPersonalJson from "@/ts/components/EtPersonalJson";
import type {EtPersonalJsonData} from "@/ts/components/Types/EtPersonalJsonData";
import {usePersonalJsonStore} from "@/ts/components/PiniaStores/PersonalJsonStore";
import {useArtikelStore} from "@/ts/components/PiniaStores/ArtikelStore";
import VariableCollector from "@/ts/components/VariableCollector";
import Merkliste from "@/ts/components/Types/Merkliste";
import {useMerklisteStore} from "@/ts/components/PiniaStores/MerklisteStore";
import type Merkzettel from "@/ts/components/Types/Merkzettel";

export default class PiniaStoreManager {
    private pinia: Pinia = createPinia();
    private artikelStore = useArtikelStore(this.pinia);
    private translationStore = useTranslationStore(this.pinia);
    private personalJsonStore = usePersonalJsonStore(this.pinia);
    private merklisteStore = useMerklisteStore(this.pinia);

    public autoSetVariables(jsonData): void {
        if(jsonData["artikel"]) {
            this.setArtikel(jsonData["artikel"]);
        }
        if(jsonData["translation"]) {
            this.setTranslation(jsonData["translation"]);
        }
        if(jsonData["merkliste"]){
            this.setMerkliste(jsonData["merkliste"]);
        }
    }

    public fetchNewVariablesFromTemplates() {
        (new VariableCollector(this)).updateAllVariables().then((jsonData) => {
            this.autoSetVariables(jsonData);
        });
    }

    public setArtikel(artikelData: Artikel){
        this.artikelStore.setArtikel(this.createArtikelObject(artikelData));
    }

    public getArtikelStore() {
        return this.artikelStore;
    }

    public setMerkliste(merklisteData: Merkliste): void {
        this.merklisteStore.setMerkliste(this.createMerklisteObject(merklisteData));
    }

    public getMerklisteStore() {
        return this.merklisteStore;
    }

    /**
     *
     */
    public async getPersonalJsonData(): Promise<EtPersonalJsonData> {
        const result = await EtPersonalJson.getInstance().fetchData();
        if (!result) {
            return {} as EtPersonalJsonData;
        }
        this.personalJsonStore.setPersonalJson(result);
        return result;
    }

    public setTranslation(translationData: Translation): void {
        this.translationStore.setTranslation(this.createTranslationObject(translationData));
    }

    public getTranslationStore() {
        return this.translationStore;
    }

    public createMerklisteObject(merkliste: Merkliste): Merkliste {
        const merkzettel: Merkzettel[] = [];
        for (const merkzettelId in merkliste.merkzettel) {
            merkzettel.push(this.createMerkzettelObject(merkliste.merkzettel[merkzettelId]));
        }

        return {
            sidebar: merkliste.sidebar || "",
            isLoggedIn: merkliste.isLoggedIn || false,
            loggedInText: this.decodeHtml(merkliste.loggedInText) || "",
            emptyMerklisteText: this.decodeHtml(merkliste.emptyMerklisteText) || "",
            bottomText: this.decodeHtml(merkliste.bottomText) || "",
            merkzettel: merkzettel || [],
        };
    }

    private createMerkzettelObject(merkzettel: Merkzettel): Merkzettel {
        const artikel: Artikel[] = [];
        if (merkzettel.artikel) {
            for (const artikelId in merkzettel.artikel) {
                artikel.push(this.createArtikelObject(merkzettel.artikel[artikelId]));
            }
        }
        return {
            id: merkzettel.id || 0,
            description: merkzettel.description || "",
            imageView: merkzettel.imageView || "",
            artikel: artikel || [],
        };
    }

    private createArtikelObject(artikel: Artikel): Artikel {
        return {
            id: artikel.id || "",
            name: artikel.name || "",
            previewImage: artikel.previewImage || "",
            image: artikel.image || "",
            price: parseFloat(String(artikel.price || 0)),
            availability: artikel.availability || 0,
            shortFacts: artikel.shortFacts || "",
            shortDescription: artikel.shortDescription || "",
            url: artikel.url || "",
            lpvbs: artikel.lpvbs || "",
            steps: artikel.steps || 1,
            min: artikel.min || 1,
            max: artikel.max || 9999,
            menge: artikel.menge || 1,
            updateUrl: artikel.updateUrl?.replace(/&amp;/g, "&") || "",
            betragEinzel: artikel.betragEinzel || 0,
            description: artikel.description || "",
            variantenText: artikel.variantenText || "",
            bestellnummer: artikel.bestellnummer || "",
            rabattText: artikel.rabattText || "",
            deliveryStatusClass: artikel.deliveryStatusClass || "",
            deliveryStatusText: artikel.deliveryStatusText || "",
            typ: artikel.typ || "",
            hasNachlieferposition: artikel.hasNachlieferposition || false,
            nachlieferpositionDescription: artikel.nachlieferpositionDescription || "",
            removeUrl: artikel.removeUrl?.replace(/&amp;/g, "&") || "",
            priceDescription: artikel.priceDescription || "",
            posType: artikel.posType || 0,
            isStandardArtikel: artikel.isStandardArtikel || true,
            isRueckstand: artikel.isRueckstand || false,
            isAusverkauft: artikel.isAusverkauft || false,
            isNoValidRueckstand: artikel.isNoValidRueckstand || false,
            wkPosId: artikel.wkPosId || 0,
            status: artikel.status || 0,
        };
    }

    private createTranslationObject(translation: Translation): Translation {
        return {
            firma: translation.firma || "",
            umsatzsteuer_id: translation.umsatzsteuer_id || "",
            anrede: translation.anrede || "",
            herr: translation.herr || "",
            frau: translation.frau || "",
            divers: translation.divers || "",
            vorname: translation.vorname || "",
            nachname: translation.nachname || "",
            plz: translation.plz || "",
            ort: translation.ort || "",
            strasse: translation.strasse || "",
            hausnummer: translation.hausnummer || "",
            adresszusatz: translation.adresszusatz || "",
            land: translation.land || "",
            telefon: translation.telefon || "",
            geburtsdatum: translation.geburtsdatum || "",
            email_adresse: translation.email_adresse || "",
            abweichende_rechnungs_email_adresse: translation.abweichende_rechnungs_email_adresse || "",
            email_wiederholung: translation.email_wiederholung || "",
            lieferadresse: translation.lieferadresse || "",
            wie_die_rechnungsanschrift: translation.wie_die_rechnungsanschrift || "",
            abweichende_lieferanschrift: translation.abweichende_lieferanschrift || "",
            packstation: translation.packstation || "",
            packstation_nummer: translation.packstation_nummer || "",
            postnummer: translation.postnummer || "",
            packstation_finden: translation.packstation_finden || "",
            rechnung: translation.rechnung || "",
            dem_paket_beilegen: translation.dem_paket_beilegen || "",
            an_rechnungsadresse_versenden: translation.an_rechnungsadresse_versenden || "",
            newsletter_register_text: translation.newsletter_register_text || "",
            pflichtfelder: translation.pflichtfelder || "",
            weiter_zur_kontrolle: translation.weiter_zur_kontrolle || "",
            weiter_zur_zahlungsauswahl: translation.weiter_zur_zahlungsauswahl || "",
            datenschutzbestimmung: translation.datenschutzbestimmung || "",
            passwort: translation.passwort || "",
            passwort_wiederholen: translation.passwort_wiederholen || "",
            artikel: translation.artikel || "",
            anzahl: translation.anzahl || "",
            gesamtpreis: translation.gesamtpreis || "",
            loeschen: translation.loeschen || "",
            deliveryTime: translation.deliveryTime || "",
            lieferung_in_einer_sendung: translation.lieferung_in_einer_sendung || "",
            bitte_aendern_entfernen_sie_zuerst_die_nachlieferposition_unten: translation.bitte_aendern_entfernen_sie_zuerst_die_nachlieferposition_unten || "",
            zwischensumme: translation.zwischensumme || "",
            versandkosten: translation.versandkosten || "",
            gesamtsumme: translation.gesamtsumme || "",
            gutschein_hinzufuegen: translation.gutschein_hinzufuegen || "",
            lieferung: translation.lieferung || "",
            bitte_waehlen_sie_eine_der_folgenden_nachlieferoptionen: translation.bitte_waehlen_sie_eine_der_folgenden_nachlieferoptionen || "",
            optional: translation.optional || "",
            rabattcode_hier_eingeben_oder_einfuegen: translation.rabattcode_hier_eingeben_oder_einfuegen || "",
            einloesen: translation.einloesen || "",
            gutschein: translation.gutschein || "",
            gutschein_entfernen: translation.gutschein_entfernen || "",
            portofrei: translation.portofrei || "",
            gratiszugabe: translation.gratiszugabe || "",
            warenkorb: translation.warenkorb || "",
            online_bestellschein: translation.online_bestellschein || "",
            oder: translation.oder || "",
            weiter_zur_kasse: translation.weiter_zur_kasse || "",
            der_mindestbestellwert_reiner_warenwert_ohne_versandkosten_betraegt: translation.der_mindestbestellwert_reiner_warenwert_ohne_versandkosten_betraegt || "",
            artikel_nr: translation.artikel_nr || "",
            bitte_ueberpruefen_sie_ihre_bestellung: translation.bitte_ueberpruefen_sie_ihre_bestellung || "",
            moechten_sie_uns_noch_etwas_mitteilen: translation.moechten_sie_uns_noch_etwas_mitteilen || "",
            ich_wurde_geworben: translation.ich_wurde_geworben || "",
            angaben_des_werbers: translation.angaben_des_werbers || "",
            kundennummer_des_werbers: translation.kundennummer_des_werbers || "",
            nachname_des_werbers: translation.nachname_des_werbers || "",
            vorname_des_werbers: translation.vorname_des_werbers || "",
            gute_wahl_das_sind_ihre_artikel: translation.gute_wahl_das_sind_ihre_artikel || "",
            gute_wahl_das_ist_ihr_artikel: translation.gute_wahl_das_ist_ihr_artikel || "",
            weiter_einkaufen: translation.weiter_einkaufen || "",
            die_angegebene_artikelnummer_wurde_nicht_gefunden: translation.die_angegebene_artikelnummer_wurde_nicht_gefunden || "",
            bitte_geben_sie_einen_vornamen_an: translation.bitte_geben_sie_einen_vornamen_an || "",
            bitte_geben_sie_einen_nachnamen_an: translation.bitte_geben_sie_einen_nachnamen_an || "",
            bitte_geben_sie_eine_gueltige_postleitzahl_plz_an: translation.bitte_geben_sie_eine_gueltige_postleitzahl_plz_an || "",
            bitte_geben_sie_einen_ort_an: translation.bitte_geben_sie_einen_ort_an || "",
            bitte_geben_sie_eine_gueltige_postnummer_an: translation.bitte_geben_sie_eine_gueltige_postnummer_an || "",
            bitte_geben_sie_eine_strasse_an: translation.bitte_geben_sie_eine_strasse_an || "",
            bitte_geben_sie_eine_hausnummer_an: translation.bitte_geben_sie_eine_hausnummer_an || "",
            bitte_geben_sie_einen_gutschein_an: translation.bitte_geben_sie_einen_gutschein_an || "",
            meine_merkzettel: translation.meine_merkzettel || "",
            name_des_merkzettels: translation.name_des_merkzettels || "",
            merkzettel_erstellen: translation.merkzettel_erstellen || "",
            bearbeiten: translation.bearbeiten || "",
            alle_artikel: translation.alle_artikel || "",
            merkzettel_und_alle_artikel_darin_loeschen: translation.merkzettel_und_alle_artikel_darin_loeschen || "",
            merkzettel_loeschen: translation.merkzettel_loeschen || "",
            merkzettel_umbenennen: translation.merkzettel_umbenennen || "",
            uebernehmen: translation.uebernehmen || "",
            alles_loeschen: translation.alles_loeschen || "",
            merkzettel_loeschen_und_die_artikel_auf_eine_andere_liste_uebertragen: translation.merkzettel_loeschen_und_die_artikel_auf_eine_andere_liste_uebertragen || "",
            zurueck: translation.zurueck || "",
            artikel_uebertragen: translation.artikel_uebertragen || "",
            verschieben: translation.verschieben || "",
            alles_auswaehlen: translation.alles_auswaehlen || "",
            in_den_warenkorb: translation.in_den_warenkorb || "",
            artikeldetails: translation.artikeldetails || "",
            hinzufuegen: translation.hinzufuegen || "",
        };
    }

    private decodeHtml(html: string): string {
        return new DOMParser().parseFromString(html, "text/html").documentElement.textContent ?? "";
    }
}
