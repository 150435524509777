import Autocomplete from "~/bootstrap5-autocomplete/autocomplete";

/**
 * Einbindung von https://github.com/lekoala/bootstrap5-autocomplete v1.1.25
 */
export default class EtFormAutoComplete {

    constructor(formElement?: HTMLFormElement) {
        Autocomplete.init();

        if (formElement && formElement instanceof HTMLFormElement) {
            this.initForm(formElement);
        } else {
            this.initForms();
        }
    }

    private initForm(formElement: HTMLFormElement) {
        if (formElement.dataset.autocompleteInitialized === "true") {
            return;
        }
        this.initOsmAddressAutocomplete(formElement);
        formElement.setAttribute("data-autocomplete-initialized", "true");
    }

    private initForms() {
        document.querySelectorAll("form").forEach((form: HTMLFormElement) => {
            this.initForm(form);
        });
    }

    /**
     * Autocomplete für Adressen über OSM
     */
    private initOsmAddressAutocomplete(formElement: HTMLFormElement) {
        const prefixes = ["la", "p", "gp", "p_p"];
        const elements = ["_strasse","_ort","_plz","_lkz"];
        prefixes.forEach((prefix) => {
            elements.forEach((element) => {
                const docElement = formElement.querySelector(`#${prefix}${element}`);
                if (!docElement || element === "_lkz") return;

                let requestLabel = "";
                switch (element) {
                case "_strasse":
                    requestLabel = "street";
                    break;
                case "_ort":
                    requestLabel = "city";
                    break;
                case "_plz":
                    requestLabel = "zip";
                    break;
                case "_lkz":
                    requestLabel = "country_code";
                    break;
                }

                Autocomplete.getOrCreateInstance(docElement, {
                    labelField: requestLabel,
                    valueFields: requestLabel,
                    showAllSuggestions: true,
                    highlightTyped: true,
                    server: "/json.php",
                    liveServer: true,
                    serverParams: this.getOsmParams(prefix),
                    debounceTime: 300,
                    onBeforeFetch: (data) => {
                        // Parameter müssen manuell aktualisiert werden
                        data._config.serverParams = this.getOsmParams(prefix);
                    },
                    onAfterFetch: (data) => {
                        if(data.length === 0) return;
                        switch (requestLabel) {
                        case "zip":
                            // Auswahl der PLZ wird auf Ort übertragen
                            data._dropElement.childNodes.forEach((child, i) => {
                                const city = data._items[i]?.city ?? "";
                                const anchor = child.querySelector("a");
                                if (!anchor) return;
                                anchor.innerHTML += " " + city;
                                child.addEventListener("click", () => {
                                    const cityElement = formElement.querySelector(`#${prefix}_ort`) as HTMLInputElement | null;
                                    if (cityElement) {
                                        cityElement.value = city;
                                        // Events für die Validierung auslösen
                                        ["input", "change", "blur"].forEach(event => cityElement.dispatchEvent(new Event(event)));
                                    }
                                });
                            });
                            break;
                        case "city":
                            // Auswahl des Ortes wird auf PLZ übertragen
                            data._dropElement.childNodes.forEach((child, i) => {
                                const zip = data._items[i]?.zip ?? "";
                                const anchor = child.querySelector("a");
                                if (!anchor) return;
                                anchor.innerHTML = zip + " " + anchor.innerHTML;
                                child.addEventListener("click", () => {
                                    const zipElement = formElement.querySelector(`#${prefix}_plz`) as HTMLInputElement | null;
                                    if (zipElement) {
                                        zipElement.value = zip;
                                        // Events für die Validierung auslösen
                                        ["input", "change", "blur"].forEach(event => zipElement.dispatchEvent(new Event(event)));
                                    }
                                });
                            });
                            break;
                        case "street":
                            // Auswahl der Straße wird auf PLZ und Ort übertragen
                            data._dropElement.childNodes.forEach((child, i) => {
                                const zip = data._items[i]?.zip ?? "";
                                const city = data._items[i]?.city ?? "";
                                const anchor = child.querySelector("a");
                                if (!anchor) return;
                                anchor.innerHTML += " (" + zip + " " + city + ")";
                                child.addEventListener("click", () => {
                                    const zipElement = formElement.querySelector(`#${prefix}_plz`) as HTMLInputElement | null;
                                    if (zipElement) {
                                        zipElement.value = zip;
                                        // Events für die Validierung auslösen
                                        ["input", "change", "blur"].forEach(event => zipElement.dispatchEvent(new Event(event)));
                                    }
                                    const cityElement = formElement.querySelector(`#${prefix}_ort`) as HTMLInputElement | null;
                                    if (cityElement) {
                                        cityElement.value = city;
                                        // Events für die Validierung auslösen
                                        ["input", "change", "blur"].forEach(event => cityElement.dispatchEvent(new Event(event)));
                                    }
                                });
                            });
                            break;
                        }
                    }
                });
            });
        });
    }

    private getOsmParams(prefix: string): Record<string, string> {
        const streetElement = document.querySelector(`#${prefix}_strasse`) as HTMLInputElement | null;
        const cityElement = document.querySelector(`#${prefix}_ort`) as HTMLInputElement | null;
        const zipElement = document.querySelector(`#${prefix}_plz`) as HTMLInputElement | null;
        let countryElement = document.querySelector(`#${prefix}_lkz`) as HTMLInputElement | null;
        if(!countryElement) countryElement = document.querySelector("#p_lkz") as HTMLInputElement | null;

        const queryParams = {};
        queryParams["service"] = "address_osm_search";
        if (streetElement && streetElement.value.length > 0) {
            queryParams["street"] = streetElement.value;
        }
        if (cityElement && cityElement.value.length > 0) {
            queryParams["city"] = cityElement.value;
        }
        if (zipElement && zipElement.value.length > 0) {
            queryParams["zip"] = zipElement.value;
        }
        if (countryElement && countryElement.value.length > 0) {
            queryParams["country"] = countryElement.value.toLowerCase();
        }

        return queryParams;
    }
}