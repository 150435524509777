
export default class EtVideoLoader {

    constructor() {
        const enableVideos = document.querySelectorAll('.ytposter');
        enableVideos.forEach((enableVideo) => {
            if(enableVideo.classList.contains("etvideoloader_initialized")) return;
            enableVideo.classList.add("etvideoloader_initialized");

            enableVideo.addEventListener('click', (e) => {
                e.preventDefault();
                this.activateVideo(enableVideo.closest('.ytwrapper'));
            });
        });

        const youtubeVideos = document.querySelectorAll<HTMLElement>('.youtubevideo');
        youtubeVideos.forEach(video => {
            video.addEventListener('click', (e) =>{
                e.preventDefault();
                this.displayVideo(video);
            });
        });
    }

    private activateVideo(wrapper) {
        const iframe = wrapper.querySelector('.ytframe');
        const src = iframe.getAttribute('data-src');
        wrapper.classList.add('ytwrapper_active');
        iframe.setAttribute('src', src);
    }

    private displayVideo(video) {
        const yw = video.dataset.yw;
        const yh = video.dataset.yh;
        const yid = video.dataset.yid;
        if (yw && yh && yid) {
            video.innerHTML = `<iframe width="${yw}" height="${yh}" src="http://www.youtube.com/embed/${yid}?autoplay=1&fs=0&rel=0&showinfo=0&disablekb=1&enablejsapi=1" frameborder="0"></iframe>`;
        } else {
            console.error("Missing data attributes on the clicked video element.");
        }
    }

}