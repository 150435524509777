import EtConsoleBufferItem from "@/ts/components/Types/EtConsoleBufferItem";
import {AnyType} from "@/ts/components/Types/AnyType";

export default class EtConsole implements Console {
    Console: console.ConsoleConstructor = console.Console;

    memory: AnyType;
    private verbose: boolean;
    private buffer: EtConsoleBufferItem[];

    constructor(verbose?: boolean, buffer?: never[]) {
        // Set verbose mode based on the environment
        this.verbose = verbose || false;
        this.buffer = buffer || [];
    }

    profile(label?: string | undefined): void {
        throw new Error("Method not implemented.");
    }

    profileEnd(label?: string | undefined): void {
        throw new Error("Method not implemented.");
    }

    /**
     * Enable or disable verbose mode.
     * @param to - If true, verbose mode is enabled. If false, verbose mode is disabled.
     */
    setVerbose(to: boolean) {
        this.verbose = to;

        // Send buffered log messages to the console if verbose mode is enabled
        if (this.verbose) {
            for (const item of this.buffer) {
                this._sendToConsole(item.channel, item.data, item.ts);
            }
            this.buffer = [];
        }
    }

    /**
     * Sends the log message to the console.
     * If verbose mode is disabled, the message is buffered.
     * @param channel - The log channel or type.
     * @param data - The log message or data to be logged.
     * @param ts - The timestamp of the log message.
     */
    private _sendToConsole(channel: string, data?: any, ts?: number): void {
        if (!ts || typeof ts !== typeof 0) {
            ts = Math.round(performance.now() * 10000) / 10000;
        }

        if (!this.verbose) {
            this.buffer.push({channel, ts, data});
            return;
        }

        if (channel in console) {
            console[channel](...data);
        } else if (channel === "success") {
            const styles = ["color:white", "background:green"].join(";");
            console.log("%c%s", styles, ...data);
        }
    }

    // Implementing the Console interface methods

    /**
     * Logs a message and throws an error if the provided condition is false.
     * @param condition - The condition to check.
     * @param data - The log message or data to be logged.
     */
    assert(condition?: boolean, ...data: AnyType[]): void {
        console.assert(!!condition, ...data);
    }

    /**
     * Clears the console.
     */
    clear(): void {
        console.clear();
    }

    /**
     * Logs the number of times this particular line of code has been called with an optional label.
     * @param label - The label to identify the counter.
     */
    count(label?: string): void {
        console.count(label || "");
    }

    /**
     * Resets the counter for the given label, or all counters if no label is provided.
     * @param label - The label of the counter to reset.
     */
    countReset(label?: string): void {
        console.countReset(label || "");
    }

    /**
     * Logs a message with the debug level.
     * @param data - The log message or data to be logged.
     */
    debug(...data: AnyType[]): void {
        this._sendToConsole("debug", data);
    }

    /**
     * Displays an interactive listing of the properties of the specified JavaScript object.
     * @param item - The object to display.
     */
    dir(item?: AnyType): void {
        console.dir(item);
    }

    /**
     * Displays an XML/HTML Element representation of the specified object.
     * @param data - The log message or data to be logged.
     */
    dirxml(...data: AnyType[]): void {
        console.dirxml(...data);
    }

    /**
     * Logs a message with the error level.
     * @param data - The log message or data to be logged.
     */
    error(...data: AnyType[]): void {
        this._sendToConsole("debug", data);
    }

    /**
     * Throws an error with the provided message and optional additional parameters.
     * @param message - The error message.
     * @param optionalParams - Additional parameters to include in the error message.
     */
    exception(message?: string, ...optionalParams: AnyType[]): void {
        console.error(message, ...optionalParams);
    }

    /**
     * Creates a new inline group in the console.
     * @param data - The log message or data to be logged.
     */
    group(...data: AnyType[]): void {
        this._sendToConsole("group", data);
    }

    /**
     * Creates a new inline group in the console that is collapsed by default.
     * @param data - The log message or data to be logged.
     */
    groupCollapsed(...data: AnyType[]): void {
        this._sendToConsole("groupCollapsed", data);
    }

    /**
     * Exits the current inline group.
     */
    groupEnd(): void {
        this._sendToConsole("groupEnd");
    }

    /**
     * Logs a message with the info level.
     * @param data - The log message or data to be logged.
     */
    info(...data: AnyType[]): void {
        this._sendToConsole("info", data);
    }

    /**
     * Logs a message with the default log level.
     * @param data - The log message or data to be logged.
     */
    log(...data: AnyType[]): void {
        this._sendToConsole("log", data);
    }

    /**
     * Displays tabular data as a table.
     * @param data - The tabular data to be logged.
     */
    table(...data: AnyType[]): void {
        console.table(data);
    }

    /**
     * Starts a timer with an optional label.
     * @param label - The label to identify the timer.
     */
    time(label?: string): void {
        console.time(label || "");
    }

    /**
     * Stops the timer with the given label and logs the elapsed time.
     * @param label - The label of the timer to stop.
     */
    timeEnd(label?: string): void {
        console.timeEnd(label || "");
    }

    /**
     * Logs the current value of the specified timer.
     * @param label - The label of the timer.
     * @param data - The log message or data to be logged.
     */
    timeLog(label?: string, ...data: AnyType[]): void {
        console.timeLog(label || "", ...data);
    }

    /**
     * Adds a marker to the browser's Timeline or Waterfall tool.
     * @param label - The label of the marker.
     */
    timeStamp(label?: string): void {
        console.timeStamp(label || "");
    }

    /**
     * Logs a stack trace to the console.
     * @param data - The log message or data to be logged.
     */
    trace(...data: AnyType[]): void {
        console.trace(...data);
    }

    /**
     * Logs a message with the warning level.
     * @param data - The log message or data to be logged.
     */
    warn(...data: AnyType[]): void {
        this._sendToConsole("warn", data);
    }

    /**
     * Logs a message with a custom "success" channel.
     * @param data - The log message or data to be logged.
     */
    success(...data: AnyType[]): void {
        this._sendToConsole("success", data);
    }
}
