import "@/scss/_main.scss";
import allPageInit from "@/ts/pages/all";
import EtailerFrontend from "@/ts/components/EtailerFrontend";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import {getSpinnerHtml, hideSpinner, showSpinner} from "@/ts/components/Spinner";
import {olk_ShowHTML} from "@/ts/components/OnlinekaufLegacy";
import {
    addMultipleProductsToCart,
    elementArtikelAddToCart,
    elementArtikelChangeVariant,
    selectVariant
} from "@/ts/components/artikelFunctions";
import {Popover, Tooltip} from "bootstrap";
import {peeriusShowWidgets, WidgetDataList} from "@/ts/components/Externals/PeeriusApi";
import InitScrollToTop from "@/ts/components/ScrollToTop";

declare global {
    function showSpinner():void;
    function hideSpinner():void;
    // Legacy onlinekauf functions - used in some old textblocks
    function olk_ShowHTML(windowName: string, anchorElement: HTMLAnchorElement, options: string):boolean;
    function selectVariant(masterId: number, variantId: number, selector: string): void
    function elementArtikelChangeVariant(selectElement:HTMLSelectElement, activateVariantId?:number):boolean;
    function elementArtikelAddToCart(buttonElement: HTMLButtonElement): any;
    function cycleBtnShapes(btnElement:HTMLElement, list?:number[]):void;
    function peeriusShowWidgets(widgetDataList:WidgetDataList, langCode:string):void;
    function addMultipleProductsToCart(productList: Map<number, number>):void;
    function showTooltip(element, message):void;
    function historyBack():void;
}

window.selectVariant = selectVariant;
window.showSpinner = showSpinner;
window.hideSpinner = hideSpinner;
window.olk_ShowHTML = olk_ShowHTML;
window.elementArtikelChangeVariant = elementArtikelChangeVariant;
window.elementArtikelAddToCart = elementArtikelAddToCart;
window.cycleBtnShapes = cycleBtnShapes;
window.peeriusShowWidgets = peeriusShowWidgets;
window.addMultipleProductsToCart = addMultipleProductsToCart;

const etailerFrontend: EtailerFrontend = new EtailerFrontend();

etailerFrontend.registerPageInit("all", "all", allPageInit);

const etData = window.etData;
if (etData) {
    // Create event listener for browser events
    etData.browserEventsFired = new Set<string>();
    document.addEventListener("DOMContentLoaded", (event: Event): void => {
        etData.browserEventsFired.add(event.type);
    });
    window.addEventListener("load", (event: Event): void => {
        etData.browserEventsFired.add(event.type);
    });

    etailerFrontend.registerPageInit(etData.vwType, etData.vwName, async (vwtype: string, vwname: string): Promise<void> => {
        try {
            const pageInitModule = await import(`@/ts/pages/${vwtype}/${vwname}`);
            const pageInit = pageInitModule.default;
            pageInit(vwtype, vwname);
        } catch (e) {
            // TODO: Handle missing import
            //console.info(`Page init for ${vwtype}/${vwname} not found`);
        }
    });

    etailerFrontend.registerPageInit(etData.vwType, "all", async (vwtype: string, vwname: string): Promise<void> => {
        try {
            const pageInitModule = await import(`@/ts/pages/${vwtype}/${vwname}`);
            const pageInit = pageInitModule.default;
            pageInit(vwtype, vwname);
        } catch (e) {
            // TODO: Handle missing import
            //console.info(`Page init for ${vwtype}/${vwname} not found`);
        }
    });

    FrontendHandler.setPublicVars({"etailerFrontend": etailerFrontend});
}

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

window.showTooltip = function (element, message) {
    const tooltip = new Tooltip(element, {
        title: message,
        trigger: 'manual',
        placement: 'top'
    });
    tooltip.show();
    setTimeout(() => {
        tooltip.dispose();
    }, 5000);
}

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl))

const spinnerHtml = "<div id=\"global-spinner\" class=\"spinner_loading_overlay\">" + getSpinnerHtml("200px") + "</div>";
document.body.insertAdjacentHTML("beforeend", spinnerHtml);

// TODO: move to separate file - development helper functions
function cycleBtnShapes(btnElement:HTMLElement, list?:number[]) {
    let currentBtnShape = Number(btnElement.dataset.btnShape) || 0;
    let newBtnShape = currentBtnShape + 1;

    if (list) {
        // find currentBtnShape in list
        let index = list.indexOf(currentBtnShape);
        if (index === -1 || index === list.length - 1) {
            newBtnShape = Number(list.shift());
        } else {
            newBtnShape = Number(list[index + 1]);
        }
    }

    if (newBtnShape > 9) {
        newBtnShape = 1;
    }
    btnElement.dataset.btnShape = String(newBtnShape);
}

window.historyBack = function() {
    if (window.history.length > 1 && document.referrer.indexOf(location.protocol + "//" + location.host) === 0) {
        window.history.back();
    } else {
        window.location.href = '/';
    }
}

InitScrollToTop(".layout_scroll_top");
