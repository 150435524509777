import EtDataType from "@/ts/components/Types/EtDataType";

type CCData = {
    gs?: {
        [key: string]: {
            a: 0 | 1;
            ad: 0 | 1 | 2;
            g: number;
        };
    };
    cs?: {
        [key: string]: {
            a: 0 | 1;
            ad: 0 | 1 | 2;
            g: number;
        };
    };
    cl: number;
    nv: number;
};

declare class etCC {
    d: CCData;
    private sKey;
    private nKey;

    constructor();

    private _rc;
    private _wc;
    private _ud;
    private _gnv;
    private _snv;

    showNotice(): boolean;

    jsn(jsn: string): void;

    g(key: string): boolean;

    c(key: string): boolean;

    pt(): void;
}

declare const CC: etCC;
declare const etData: EtDataType;
declare let custom_data: { [key: string]: number };

/**
 * TODO: Testen und anpassen.
 * Info: CC ist global per var im head definiert.
 * **/

export default class CookieControlNotice {
    constructor(cookieControlElement?: HTMLDivElement) {
        if (cookieControlElement) {
            this.initCookeControlNotice(cookieControlElement);
        } else {
            this.initCookeControlNotice();
        }
    }

    private initCookeControlNotice(cookieControlElement?: HTMLDivElement) {
        const domCcn = cookieControlElement ?? document.getElementById("cookiecontrol_notice") as HTMLDivElement;
        if (!domCcn || (domCcn && domCcn.dataset.initCookeControlNotice === "done")) {
            return;
        }
        domCcn.dataset.initCookeControlNotice = "done";
        const ccId = domCcn.dataset.ccId || "";
        const ccMin = domCcn.dataset.ccMin || "";
        const dataJSN = domCcn.getAttribute("data-jsn") || "";
        if (CC) {
            CC.jsn(dataJSN);
            //console.debug('etData.presenceObjects', JSON.stringify(etData.presenceObjects));
            const blacklist = [
                etData.presenceObjects.datenschutz_c_seite ?? -1,
                etData.presenceObjects.impressum_c_seite ?? -1,
                etData.presenceObjects.widerruf_c_seite ?? -1
            ];
            if (CC.showNotice() && !(etData.vwType === "seite" && etData.vwName === "detail" && blacklist.includes(etData.vwId))) {
                window.setTimeout(() => {
                    domCcn.classList.add("cookiecontrol_notice--visible");
                }, 1000);
            }
        }

        domCcn.querySelectorAll("button[data-cc-user-choice]").forEach(buttonElement => {
            if (!(buttonElement instanceof HTMLButtonElement)) {
                return;
            }

            buttonElement.addEventListener("click", () => {
                const userChoice = buttonElement.dataset.ccUserChoice;
                if (!userChoice) { // TODO positivliste der möglichen Werte abgleichen
                    return;
                }

                // prevent double click
                if (domCcn.dataset.fetchRunning === "true") {
                    return;
                }
                domCcn.dataset.fetchRunning = "true";

                let userSettings = null;
                if (userChoice == "userSettings") {
                    userSettings = new Map();
                    domCcn.querySelectorAll("input.cc_switch_input").forEach(input => {
                        if (input instanceof HTMLInputElement) {
                            userSettings.set(input.dataset.groupid, (input.checked ? "1" : "0"));
                        }
                    });
                }

                CC.setUserChoice(userChoice, userSettings)
                    .then((response: any) => {
                        if (response.settings) {
                            domCcn.remove();

                            if (
                                userChoice == "acceptAll"
                                && custom_data
                                && typeof custom_data === "object"
                                && Object.prototype.hasOwnProperty.call(custom_data, "cookie_mandatory")
                                && response.settings
                            ) {
                                for (const groupName in response.settings) {
                                    const customKey = "cookie_" + groupName;
                                    if (customKey in custom_data) {
                                        custom_data[customKey] = response.settings[groupName] >= 1 ? 1 : 0;
                                    }
                                }
                            }
                        } else {
                            throw new Error("Invalid response data");
                        }
                    })
                    .catch((err: string) => {
                        domCcn.dataset.fetchRunning = "false";
                        console.error(err);
                    });
            });
        });

        domCcn.querySelectorAll(".js_cookiecontrol_notice_show_settings").forEach(buttonElement => {
            if (buttonElement instanceof HTMLButtonElement) {
                buttonElement.addEventListener("click", () => {
                    domCcn.dataset.showSection = "settings";
                });
            }
        });

        domCcn.querySelectorAll(".cc_switch").forEach(buttonElement => {
            if (buttonElement instanceof HTMLLabelElement) {
                buttonElement.addEventListener("click", (event) => {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                });
            }
        });
    }
}
