import type {EtPersonalJsonData} from "@/ts/components/Types/EtPersonalJsonData";
import EtConsole from "@/ts/components/EtConsole";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import FetchStatusEnum from "@/ts/components/Enums/FetchStatusEnum";
import {AnyType} from "@/ts/components/Types/AnyType";

export type EtPersonalJsonFetchOptions = {
    onError?: AnyType;
    onSuccess?: (data: AnyType) => void;
    queryString: string | { [key: string]: string | number | undefined };
    fireGlobalEvent?: boolean;
};

const EtPersonalJsonDataProxy = {
    set(target: any, property: string, value: any) {
        throw new Error("EtPersonalJsonData is not writable");
        // console.log(`Setting ${property} to ${value}`);
        // target[property] = value;
        // return true;
    },
    get(target: any, property: string) {
        //console.log(`Getting ${property}`);
        return target[property];
    },
    has(target: any, property: string) {
        //console.log(`Checking if ${property} exists`);
        return property in target;
    },
};

/**
 * @TODO
 * This need to be refactored. Maybe separate the fetching into its own class with an
 * interface that we can mock away the real connection with a dummy for unit tests.
 *
 * Further we should also put the fetch cache in its own class that makes it possible to cache
 * even across multiple classes
 *
 * We should also minimize the use of singleton so classes like this should be not singleton.
 * One idea could be a SingletonFactory that take care that there is only one instance of a
 * specific class but the concrete class itself is not a singleton. We should brainstorm this in
 * the team if it is an appropriate solution.
 */

/**
 * The EtPersonalJson class provides functionality for fetching and applying personal.json data.
 */
export default class EtPersonalJson {
    protected window: Window;
    protected document: Document;
    protected status: FetchStatusEnum;
    protected fetchCache: Map<string, EtPersonalJsonData>;
    protected metaData: Map<string, any>;
    protected callbacks:any[];
    protected lastFetchedData: EtPersonalJsonData|null;

    private static instance?: EtPersonalJson;
    public static numInstances = 0; // instance counter for debugging

    private constructor(w?: Window) {
        if (EtPersonalJson.instance) {
            throw new Error("EtPersonalJson is a singleton class. Use EtPersonalJson.getInstance() instead.");
        }

        this.window = w ?? window;
        this.document = this.window.document;
        this.status = FetchStatusEnum.UNINITIALIZED;
        this.fetchCache = new Map();
        this.metaData = new Map();
        this.callbacks = [];
        this.lastFetchedData = null;

        // bind event listener
        // this.document.addEventListener("et.personalJsonResult", this.documentEventHandler.bind(this), {passive: true});
    }

    public static getInstance(win?: Window): EtPersonalJson {
        if (!EtPersonalJson.instance) {
            EtPersonalJson.instance = new EtPersonalJson(win);
        }

        return EtPersonalJson.instance;
    }

    /**
     * Gets the current status of the EtPersonalJson instance.
     * @returns {string} - The current status.
     */
    getStatus(): FetchStatusEnum {
        return this.status;
    }

    /**
     * Fetches the personal.json data.
     * @param useCache
     * @param {EtPersonalJsonFetchOptions} [options] - The fetch options.
     */
    public async fetchData(useCache = true, options?: EtPersonalJsonFetchOptions): Promise<EtPersonalJsonData> {
        // wait for former request
        while (this.status === FetchStatusEnum.PENDING) {
            await new Promise(resolve => setTimeout(resolve, 10));
        }

        if (!options) {
            options = {queryString: {}};
        }
        if (!options.hasOwnProperty("fireGlobalEvent")) {
            options.fireGlobalEvent = true;
        }

        try {
            const request = this.createFetchRequest(options);

            if (this.fetchCache.has(request.url) && useCache) {
                const cache = this.fetchCache.get(request.url) as EtPersonalJsonData;
                await this.processFetchedData(options, cache);
                return cache;
            } else {
                this.status = FetchStatusEnum.PENDING;
                const response = await fetch(request)
                    .catch(err => {
                        FrontendHandler.getPublicVar<EtConsole>("etConsole").error("fetch error: ", err);
                        this.status = FetchStatusEnum.FAILED;

                        if (
                            options
                            && typeof options === "object"
                            && Object.hasOwn(options.hasOwnProperty, "onError")
                            && typeof options.onError === "function"
                        ) {
                            options.onError.call(null);
                        }
                    });

                if (response?.status !== 200) {
                    throw new Error("Network response was not ok.");
                }

                const data = await response.json() as EtPersonalJsonData;
                // console.timeStamp("EtPersonalJson.fetchData");

                if (data && typeof data !== "object") {
                    this.status = FetchStatusEnum.FAILED;
                }
                this.fetchCache.set(request.url, data);
                this.lastFetchedData = data;
                this.status = FetchStatusEnum.DONE;


                await this.processFetchedData(options, data); // await ist hier wichtig fürs timing, der folgenden .then

                return Promise.resolve(data);
            }
        } catch (e) {
            return Promise.reject("EtPersonalJson fetch failed");
            // throw new Error("EtPersonalJson fetch failed");
        }
    }

    protected async processFetchedData(options: EtPersonalJsonFetchOptions, data: EtPersonalJsonData) {
        // console.timeStamp("EtPersonalJson.processFetchedData");
        if (options?.fireGlobalEvent === false) {
            return;
        }

        this.applyGenericReplacements(data);

        document.dispatchEvent(
            new CustomEvent("et.personalJsonResult", {
                detail: data
            })
        );

        if ("form" in data && data?.form?.token) {
            const inputElement = document.querySelector(".keep-pt input") as HTMLInputElement;
            if (inputElement) {
                inputElement.value = data.form.token;
            }
        }

        if (
            options
            && typeof options === "object"
            && Object.hasOwn(options, "onSuccess")
            && typeof options.onSuccess === "function"
        ) {
            options.onSuccess.call(null, data);
        }
    }

    protected createFetchRequest(options: EtPersonalJsonFetchOptions): Request {
        let queryString = "";
        switch (typeof options.queryString) {
        case "string":
            if (options.queryString.indexOf("?") > 0) {
                FrontendHandler.getPublicVar<EtConsole>("etConsole")
                    .error("queryString invalid #2", JSON.stringify({
                        type: typeof queryString,
                        queryString: queryString
                    }));
                throw new Error("queryString");
            }
            queryString = options.queryString as string;
            break;
        case "object":
            queryString = Object.entries(options.queryString)
                .map((key, value): string => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            break;
        default:
            FrontendHandler.getPublicVar<EtConsole>("etConsole")
                .error("queryString invalid #3", JSON.stringify({
                    type: typeof queryString,
                    queryString: queryString
                }));
            throw new Error("options.queryString");
        }

        return new Request(this.createRequestUrl(queryString), {
            method: "GET",
            cache: "no-cache",
            credentials: "same-origin",
        });
    }

    protected createRequestUrl(queryString: string): URL {
        const baseUrl = this.window.location.protocol + "//" + this.window.location.host;
        let relativeUrl = "/personal.json";
        if (queryString.length > 0) {
            relativeUrl += "?" + queryString;
        }

        const url = new URL(relativeUrl, baseUrl);

        if (!url.searchParams.has("vw_type")) {
            url.searchParams.set("vw_type", window.etData.vwType);
        }

        if (!url.searchParams.has("vw_name")) {
            url.searchParams.set("vw_name", window.etData.vwName);
        }

        if (!url.searchParams.has("vw_id")) {
            url.searchParams.set("vw_id", window.etData.vwId.toString());
        }

        let inputElement = document.querySelector(".keep-pt input") as HTMLInputElement;
        if (inputElement) {
            url.searchParams.set('form', '1');
        }

        return url;
    }

    /**
     * Applies generic replacements based on the personal.json data.
     * @param {EtPersonalJsonData} pjData - The personal.json data.
     */
    applyGenericReplacements(pjData: EtPersonalJsonData): void {
        for (const [pjDataKey, pjDataValue] of Object.entries(pjData)) {
            //console.log(pjDataKey);
            //console.log(pjDataValue);
            if (pjDataValue && typeof pjDataValue === "object" && (pjDataValue?.numItems || pjDataValue?.numPos)) {
                const numItems = pjDataValue?.numPos ?? pjDataValue?.numItems;
                document.querySelectorAll(`[data-et-pj-counter-name="${pjDataKey}"]`).forEach((dataElement: Element) => {
                    if (dataElement instanceof HTMLElement) {
                        dataElement.dataset.etPjCounterValue = String(numItems === 0 ? "" : numItems);
                        dataElement.hidden = false;
                    }
                });
                if (pjDataKey == "wishlist" && typeof numItems == "number" && numItems > 0) {
                    document.getElementById("wishlist_icon")?.classList.remove("d-none");
                }
            }
        }

        if (pjData?.dom) {
            Object.entries(pjData.dom).forEach(([domKey, domData]): void => {
                if (domData.html) {
                    document.querySelectorAll(`[data-et-pj-dom-html="${domKey}"]`).forEach((dataElement: Element) => {
                        if (dataElement instanceof HTMLElement) {
                            dataElement.innerHTML = domData.html ?? "";
                        }
                    });
                }
                if (domData.attributes) {
                    const attributes: [string, any][] = Object.entries(domData.attributes);
                    if (attributes.length) {
                        document.querySelectorAll(`[data-et-pj-dom-attrs="${domKey}"]`).forEach((dataElement: Element) => {
                            if (dataElement instanceof HTMLElement) {
                                attributes.forEach(pair => {
                                    if (pair[1] === false) {
                                        dataElement.removeAttribute(pair[0]);
                                    } else {
                                        dataElement.setAttribute(pair[0], pair[1]);
                                    }
                                });
                            }
                        });
                    }
                }
            });
        }
    }

    /**
     *
     * @deprecated dont use this anymore!
     * @use addEventHandler instead
     * @use fetchData instead
     * @param callback
     * @param fetchOptions
     */
    public addEventListener(
        callback: (data: EtPersonalJsonData) => void,
        fetchOptions?: EtPersonalJsonFetchOptions
    ): void {
        console.warn("EtPersonalJson.addEventListener is deprecated");
        if (this.document && callback && typeof callback === "function") {
            this.addEventHandler(callback);
        }

        this.fetchData(true, fetchOptions);
    }

    /**
     * @param callback
     * @param executeIfDataAvailable
     */
    public addEventHandler(
        callback: (data: EtPersonalJsonData) => void,
        executeIfDataAvailable = true
    ): void {
        if (callback && typeof callback === "function") {
            if (this.callbacks.includes(callback)) {
                console.warn("EtPersonalJson.addEventHandler - callback already added", callback.name);
                return;
            }
            this.callbacks.push(callback);
            document.addEventListener("et.personalJsonResult", (event) => {
                checkCustomEventDataStructure(event, callback)
            }, {passive: true});
        } else {
            throw new Error("callback is not a function");
        }

        if (executeIfDataAvailable && this.lastFetchedData) {
            callback.call(null, this.lastFetchedData);
        }
    }

    public documentEventHandler(event: Event) {
        console.debug("EtPersonalJson.documentEventHandler", event);
        if (event instanceof CustomEvent && event.detail && typeof event.detail === "object") {
            this.callbacks.forEach(callback => {
                callback.call(null, event.detail);
            });
        }
    }
}

function checkCustomEventDataStructure(event: Event, callback: (data: EtPersonalJsonData) => void): void {
    if (event instanceof CustomEvent && event.detail && typeof event.detail === "object") {
        callback.call(null, event.detail);
    }
}

