export function showSpinner() {
    const spinner = document.getElementById("global-spinner");
    if (spinner) {
        spinner.dataset.visible = "true";
    }
}

export function hideSpinner() {
    const spinner = document.getElementById("global-spinner");
    if (spinner) {
        spinner.dataset.visible = "false";
    }
}

/**
 *
 * @param {string} size - The size of the spinner. Possible values: sm, string like "1rem"
 * @param {string} color - The color of the spinner. Possible values: primary, secondary, success, danger, warning, info, light, dark
 */
export function getSpinnerHtml(size: string | null = ""): string {
    let sizeString = "";
    if (typeof size === "string") {
        if (size === "sm") {
            sizeString = " spinner-border-sm";
        } else if (size.length > 0) {
            sizeString = ` style="width:${size};height:${size}"`;
        }
    }
    return `<img src="/custom/module/public/vbsr2/public/assets/img/spinner.min.svg" loading="lazy" class="spinner-img"${sizeString} role="status">`;
}