/**
 * @TODO: Refactoring
 * move out logic of finding Slider elements in its own SliderFinder class this allows us to find
 * Slider HTMLElements in order to generate the config outside and just give this class the
 * SwiperOptions.
 *
 * further we need a SliderConfigBuilder with a SliderConfigBuilderInterface to generate
 * config objects. The SliderConfigBuilderInterface is injected into SliderConfigDirector which has
 * several functions to build common configuration presets and return a the preconfigured
 * SliderConfigBuilder which allows us afterwards to fine tune the config for
 * special cases
 *
 * I think it could be also beneficial to encapsulate the logic of deciding which
 * config preset to generate in a EtSliderFactory which uses an SliderConfigPresetEnum to create
 * preconfigured EtSlider objects
 *
 */
import Swiper from "swiper";
import {
    A11y,
    Navigation,
    Pagination,
    Scrollbar,
    Keyboard,
    Mousewheel,
    // Zoom,
    Controller,
    Autoplay,
    Thumbs
} from "swiper/modules";
import {SwiperOptions} from "swiper/types/swiper-options";
import WindowHandler from "@/ts/components/Handlers/WindowHandler";
import EtConsole from "@/ts/components/EtConsole";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import EtSliderBreakpointBuilder from "@/ts/components/EtSliderBreakpointBuilder";

type SliderDictionary = { [key: string]: Swiper };

/**
 * This class is used to initialize all slider elements on the page.
 */
export default class EtSlider {
    static sliders: SliderDictionary = {};

    /**
     *
     * @param sliderElement if true, all sliders will be initialized
     * @param swiperOptions
     */
    constructor(sliderElement: HTMLElement | true | null = null, swiperOptions: SwiperOptions | null = null) {
        if (sliderElement instanceof HTMLElement) {
            EtSlider.initSlider(sliderElement, swiperOptions);
        } else if (sliderElement) {
            EtSlider.initAllSliders();
        }
    }

    /**
     * Initializes all slider elements on the page.
     */
    static initAllSliders(): void {
        const sliderElements: HTMLElement[] = this.getSliderElements();
        sliderElements.forEach((sliderElement: HTMLElement) => {
            this.initSlider(sliderElement).catch((reason) => {
                FrontendHandler.getPublicVar<EtConsole>("etConsole").error(reason);
            });
        });
    }

    /**
     * Initialize one slider
     * @returns Promise
     */
    static async initSlider(sliderElement: HTMLElement, sliderConfig: SwiperOptions | null = null): Promise<{
        swiperApi: Swiper,
        sliderElement: HTMLElement,
        id: string | undefined
    }> {
        return new Promise((resolve, reject) => {
            const id: string = sliderElement.id || "";

            if (!id.length) { // TODO: id sollte nicht required sein
                reject("Slider has no id attribute");
                return;
            } else if (Object.hasOwn(EtSlider.sliders, id) || sliderElement.classList.contains("swiper-initialized")) {
                reject("Slider is already initialized");
                return;
            } else {
                // if (!FrontendHandler.isVariantOfElementActive(sliderElement)) {
                /* TODO: optimieren!
                    A) generell auf hidden-attribut an allen Eltern kontrollieren
                    B) wieder über den FrontendHandler, wobei getComputedStyle immer "recalculate style" und oft "layout" erzwingt (critical rendering path) = sehr teuer
                    C) über MutationObserver, um Änderungen zu erkennen (teuer, weil parent überwachung)
                    D) über IntersectionObserver auf Viewport und Änderung der Visibility checken (Visibility müsste der mittlerweile berücksichtigen bzw nur feuern, wenn das Element auch wirklich dargestellt wird)
                */
                const p = sliderElement.closest(".vproduct") as HTMLElement;
                if (p && p.hidden) {
                    reject("Slider is in hidden element");
                    return;
                }
            }

            const swiperOptions: SwiperOptions = sliderConfig || this.getSliderConfig(sliderElement);
            /*swiperOptions.on = {
                // init: (swiper: Swiper) => this.generateButtonActions(id),
                // afterInit: (swiper: Swiper) => this.generateButtonActions(id),
                slideChange: (swiper: Swiper) => {
                    this.generateButtonActions(id, swiper);
                },
            };*/
            if (!Object.hasOwn(swiperOptions, "modules")) {
                // TODO: keep these modules in sync with the includes in swiper_v11.scss
                swiperOptions.modules = [
                    A11y,
                    Navigation,
                    Pagination,
                    Scrollbar,
                    Keyboard,
                    Mousewheel,
                    // Zoom,
                    Controller,
                    Autoplay,
                    Thumbs
                ];
            }
            // console.debug("EtSlider.initSlider", {sliderElement, swiperOptions})

            const swiperApi = new Swiper(sliderElement, swiperOptions);

            EtSlider.sliders[id] = swiperApi;
            this.generateButtonActions(id, swiperApi);

            resolve({
                swiperApi: EtSlider.sliders[id],
                sliderElement,
                id
            });
        });
    }

    /**
     * Add functionality and text to the slider buttons.
     */
    protected static generateButtonActions(containerId: string, swiperApi: Swiper): void {
        /*document.querySelectorAll(".element_swiperjs__prevent_layout_shift_button").forEach((el) => {
            el.removeAttribute("hidden");
        });*/

        const button: HTMLElement | null = document.querySelector("[data-swiper-button-container-id=\"" + containerId + "\"]");
        if (!button) return;

        // const containerId: string = button.getAttribute('data-swiper-button-container-id') ?? '';
        if (!swiperApi) {
            swiperApi = EtSlider.sliders[containerId] || null;
        }

        if (!swiperApi) return;

        const button_first_element: HTMLElement | null = document.getElementById(`${containerId}__button_first_element`);
        const button_placeholder_start: HTMLElement | null = document.getElementById(`${containerId}__button_placeholder_start`);
        if (button_first_element && button_placeholder_start) {
            button_first_element.innerText = "1";
            const isAtStart = swiperApi.activeIndex === 0 || swiperApi.activeIndex === 1;
            button_first_element.style.display = isAtStart ? "none" : "block";
            button_placeholder_start.style.display = isAtStart ? "none" : "block";
            button_first_element.onclick = () => {
                swiperApi.slideTo(0);
            };
        }

        const button_prev: HTMLElement | null = document.getElementById(`${containerId}__prev`);
        if (button_prev) {
            button_prev.onclick = () => {
                swiperApi.slidePrev();
            };
        }

        const button_next: HTMLElement | null = document.getElementById(`${containerId}__next`);
        if (button_next) {
            button_next.onclick = () => {
                swiperApi.slideNext();
            };
        }

        const button_prev_element: HTMLElement | null = document.getElementById(`${containerId}__button_prev_element`);
        if (button_prev_element) {
            const realIndex: number = swiperApi.realIndex;
            button_prev_element.style.display = realIndex > 0 ? "block" : "none";
            button_prev_element.innerText = String(realIndex > 0 ? realIndex : 0);
            button_prev_element.onclick = () => {
                swiperApi.slidePrev();
            };
        }

        const button_current_element: HTMLElement | null = document.getElementById(`${containerId}__button_current_element`);
        if (button_current_element) {
            button_current_element.innerText = String(swiperApi.realIndex + 1);
        }

        // TODO: das kann responsiv unterschiedlich sein - siehe config.breakpoints.xyz!
        const slidesPerView = Number(swiperApi.params?.slidesPerView || 1);

        const button_next_element: HTMLElement | null = document.getElementById(`${containerId}__button_next_element`);
        if (button_next_element) {
            const realIndex = swiperApi.realIndex;
            const slidesLength = swiperApi.slides.length;
            button_next_element.style.display = realIndex < (slidesLength - slidesPerView) ? "block" : "none";
            button_next_element.innerText = String(realIndex < (slidesLength - slidesPerView) ? realIndex + 2 : Math.ceil(slidesLength / slidesPerView));
            button_next_element.onclick = () => {
                swiperApi.slideNext();
            };
        }

        const button_placeholder_end: HTMLElement | null = document.getElementById(`${containerId}__button_placeholder_end`);
        const button_last_element: HTMLElement | null = document.getElementById(`${containerId}__button_last_element`);
        if (button_placeholder_end && button_last_element) {
            const realIndex = swiperApi.realIndex;
            const slidesLength = swiperApi.slides.length;
            const calculatedIndex: number = (slidesLength - slidesPerView + 1);
            const isAtEnd: boolean = calculatedIndex === realIndex + 1 || calculatedIndex === realIndex + 2;
            button_placeholder_end.style.display = isAtEnd || calculatedIndex === -1 ? "none" : "block";
            button_last_element.style.display = isAtEnd || calculatedIndex === -1 ? "none" : "block";
            button_last_element.innerText = String(calculatedIndex);
            button_last_element.onclick = () => {
                swiperApi.slideTo(slidesLength - 1);
            };
        }
    }

    /**
     * Returns all slider elements on the page.
     */
    static getSliderElements(): HTMLElement[] {
        return Array.from(document.querySelectorAll(".swiper:not([data-slider-autoinit='false']):not(.swiper-initialized)")) as HTMLElement[];
    }

    /**
     * Returns the swiper config for the given element.
     * @param sliderElement
     */
    static getSliderConfig(sliderElement: HTMLElement): SwiperOptions {
        const dataSliderPreset: string = sliderElement.dataset.sliderPreset || "";
        let config: SwiperOptions = {
            loop: false,
            slidesPerView: 1,
        };
        const builder = new EtSliderBreakpointBuilder(config);
        const slideCount = sliderElement.querySelectorAll(".swiper-slide").length;
        switch (dataSliderPreset) {
            case "slideshow":
                config.loop = true;
                break;
            case "warenkorb_flyout":
                config.direction = "vertical";
                config.slidesPerView = slideCount >= 3 ? 3 : slideCount;
                config.height = config.slidesPerView * 115;
                // config.autoHeight = true;
                break;
            case "ani_3er":
                config = builder
                    .setDefault({slidesPerView: 2, spaceBetween: 16})
                    .setMd({slidesPerView: 1})
                    .setLg({slidesPerView: 3})
                    .build();
                break;
            case "teaser_artdirection":
                config.autoplay = {
                    delay: 4000,
                };
                break;
            case "gallery":
                config.slidesPerView = "auto";
                config.grabCursor = true;
                break;
            case "pds_gallery": // primary image gallery
                // nothing special
                break;
            case "pds_varianten":
                config = builder
                    .setDefault({slidesPerView: 4})
                    .setSm({slidesPerView: 5})
                    .build();
                config.scrollbar = {
                    hide: false,
                }
                break;
            case "pds_thumbnails":
                config = builder
                    .setDefault({slidesPerView: 4})
                    .setSm({slidesPerView: 5})
                    .build();
                config.pagination = {
                    type: "progressbar",
                }
                break;
            case "pds_themensets":
                config = builder
                    .setDefault({slidesPerView: 2, spaceBetween: 16})
                    .setMd({slidesPerView: 3})
                    .setLg({slidesPerView: 4, spaceBetween: 24})
                    .build();
                break;
            case "pds_ratings_slider":
                config = builder
                    .setDefault({slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 16})
                    .setSm({slidesPerView: 2, slidesPerGroup: 2})
                    .setMd({slidesPerView: 3, slidesPerGroup: 3})
                    .setLg({slidesPerView: 4, slidesPerGroup: 4})
                    .setXl({slidesPerView: 5, slidesPerGroup: 5})
                    .build();
                // config.observeParents = true;
                if (sliderElement.parentElement) {
                    const paginationElement: HTMLElement = sliderElement.parentElement.querySelector(":scope .swiper-pagination.element_swiperjs__pagination_bottom_right") as HTMLElement;
                    if (paginationElement) {
                        config.pagination = {
                            el: paginationElement,
                            clickable: true,
                            type: "custom",
                            renderCustom: function (swiper: Swiper, current, total) {
                                let result = '';

                                if (total <= 1) {
                                    return result;
                                }

                                const indicesToShow = new Set([1, total]);
                                const offsetToShow = 1;
                                for (let i = Math.max(1, current - offsetToShow); i <= Math.min(total, current + offsetToShow); i++) {
                                    indicesToShow.add(i);
                                }

                                let lastIndexShown = 0;
                                for (let i = 1; i <= total; i++) {
                                    if (indicesToShow.has(i)) {
                                        if (i > lastIndexShown + 1) {
                                            // result += '<span class="swiper-pagination-pageno">...</span>';
                                        }
                                        result += '<span class="swiper-pagination-pageno swiper-pagination-bullet"' + (i == current ? ' data-current' : '') + '>' + i + '</span>';
                                        lastIndexShown = i;
                                    } else {
                                        result += '<span class="swiper-pagination-pageno" data-hidable>&hellip;</span>';
                                    }
                                }

                                return result;
                            }
                        }
                    }
                }
                break;
            case "product_slider":
            case "productslider":
                config = builder
                    .setDefault({slidesPerView: 1, spaceBetween: 16})
                    .setSm({slidesPerView: 2})
                    .setMd({slidesPerView: 3})
                    .setLg({slidesPerView: 4})
                    .setXl({slidesPerView: 5})
                    .build();
                if (config.breakpoints) {
                    config.breakpoints[400] = {slidesPerView: 2};
                }
                break;
            case "pds_product_slider":
                config = builder
                    .setDefault({slidesPerView: 1, spaceBetween: 16})
                    .setSm({slidesPerView: 2})
                    .setMd({slidesPerView: 3})
                    .setLg({slidesPerView: 4, spaceBetween: 24}) // 24 oder 32
                    .setXl({slidesPerView: 5})
                    .build();
                if (config.breakpoints) {
                    config.breakpoints[400] = {slidesPerView: 2};
                }
                break;
            default:
                this.addGenericSliderConfig(sliderElement, config);
                break;
        }

        this.addCommonSliderControls(sliderElement, config);

        // console.debug("swiper config", {config, sliderElement});
        return config;
    }

    protected static addCommonSliderControls(sliderElement: HTMLElement, config: SwiperOptions) {
        // detect pagination
        if (!config.pagination || (config.pagination && typeof config.pagination === 'object' && !config.pagination.el)) {
            const paginationElement: HTMLElement = sliderElement.querySelector(":scope .swiper-pagination") as HTMLElement;
            if (paginationElement) {
                const paginationConfig = config.pagination || {};
                paginationConfig.el = paginationElement;
                paginationConfig.clickable = true;

                const paginationTypeWhitelist = ['bullets', 'fraction', 'progressbar', 'custom'];
                if (paginationElement.dataset.paginationType && paginationTypeWhitelist.includes(paginationElement.dataset.paginationType)) {
                    paginationConfig.type = paginationElement.dataset.paginationType as 'bullets' | 'fraction' | 'progressbar' | 'custom';
                }

                config.pagination = paginationConfig;
            } else {
                delete config.pagination;
            }
        }

        // detect navigation/slidenav
        if (!config.navigation || (config.navigation && typeof config.navigation === 'object' && !config.navigation.nextEl)) {
            const navigationButton1: HTMLElement = sliderElement.querySelector(":scope .swiper-button-prev") as HTMLElement;
            const navigationButton2: HTMLElement = sliderElement.querySelector(":scope .swiper-button-next") as HTMLElement;
            if (navigationButton1 && navigationButton2) {
                const navigationConfig = config.navigation || {};
                if (navigationButton1 && !navigationConfig.prevEl) {
                    navigationConfig.prevEl = navigationButton1;
                }
                if (navigationButton2 && !navigationConfig.nextEl) {
                    navigationConfig.nextEl = navigationButton2;
                }
                config.navigation = navigationConfig;
            } else {
                delete config.navigation;
            }
        }

        // detect pagination
        if (!config.scrollbar || (config.scrollbar && typeof config.scrollbar === 'object' && !config.scrollbar.el)) {
            const scrollbarElement: HTMLElement = sliderElement.querySelector(":scope .swiper-scrollbar") as HTMLElement;
            if (scrollbarElement) {
                const scrollbarConfig = config.scrollbar || {};
                scrollbarConfig.el = scrollbarElement;
                scrollbarConfig.draggable = true;
                config.scrollbar = scrollbarConfig;
            } else {
                delete config.scrollbar;
            }
        }

        if (!config.autoplay) {
            // detect autoplay setting
            if (sliderElement.dataset.swiperAutoplay || sliderElement.dataset.sliderAutoplay) {
                const delay = Number(sliderElement.dataset.swiperAutoplay || sliderElement.dataset.sliderAutoplay || 0);
                if (delay >= 1000) {
                    config.autoplay = {delay};
                    config.loop = true;
                }
            } else {
                const settingElement = sliderElement.closest("[class*=\"slideshow__autoplay_delay_\"]");
                if (settingElement) {
                    const match: RegExpMatchArray | null = settingElement.className.match(/slideshow__autoplay_delay_([0-9]+)/);
                    if (match) {
                        const delay: number = Number(match[1]) || 0;
                        if (delay >= 1000) {
                            config.autoplay = {delay};
                            config.loop = true;
                        }
                    }
                }
            }
        }
    }

    protected static addGenericSliderConfig(sliderElement: HTMLElement, sliderConfig: SwiperOptions): SwiperOptions {
        const configBreakpoints: { [key: string | number]: SwiperOptions } = {};
        const config: SwiperOptions = sliderConfig || {};

        const firstSlide: HTMLElement | null = sliderElement.querySelector(".swiper-slide");
        if (firstSlide instanceof HTMLElement) {
            firstSlide.classList.forEach(function (className) {
                let matches: RegExpMatchArray | null;
                const regexBootstrap = new RegExp(/w(-sm|-md|-lg|-xl|-xxl)?-([0-9]+)$/);
                const regexUikit = new RegExp(/(et|uk)-width(-small|-medium|-large|-xlarge)?-([0-9])-([0-9])$/);

                if (className.length >= 4 && (matches = className.match(regexBootstrap))) {
                    const breakpoint: string = matches[1] ? matches[1].substring(1) : "";
                    const fraction: number = parseInt(matches[2]);
                    let slidesPerView = 1;

                    //console.debug("bs-matches", {matches, breakpoint, fraction, w:WindowHandler.getBreakpointMin(breakpoint)});
                    if (fraction > 0) { // 1 of n
                        slidesPerView = Math.floor(100 / fraction);
                        if (breakpoint.length) {
                            const w = WindowHandler.getBreakpointMin(breakpoint) || 0;
                            if (w > 0) {
                                if (Object.hasOwn(configBreakpoints, w)) {
                                    configBreakpoints[w].slidesPerView = slidesPerView;
                                } else {
                                    configBreakpoints[w] = {slidesPerView: slidesPerView};
                                }
                            }
                        } else {
                            config.slidesPerView = slidesPerView;
                        }
                    } else {
                        config.slidesPerView = slidesPerView;
                    }
                } else if (className.length >= 8 && (matches = className.match(regexUikit))) {
                    const breakpoint: string | null = matches[1] ? matches[1].substring(1) : null;
                    const zaehler: number = parseInt(matches[2]);
                    const nenner: number = parseInt(matches[3]);
                    if (zaehler === 1) { // 1 of n
                        if (breakpoint === null) {
                            config.slidesPerView = nenner;
                        } else {
                            // TODO w sauber aus den breakpoints ermitteln
                            const w: number = WindowHandler.getBreakpointMin(breakpoint) || 0;
                            // let w = 100;
                            if (w) {
                                if (Object.hasOwn(configBreakpoints, w)) {
                                    configBreakpoints[w].slidesPerView = nenner;
                                } else {
                                    configBreakpoints[w] = {slidesPerView: nenner};
                                }
                            }
                        }
                    }
                }
            });

            if (Object.keys(configBreakpoints).length) {
                config.breakpoints = configBreakpoints;
            }
        }

        return config;
    }

    /**
     * Reinitializes all swiper elements on the page.
     */
    static initSliders(): void {
        Object.values(EtSlider.sliders).forEach((swiper: Swiper) => {
            swiper.destroy(true, true);
        });
        EtSlider.sliders = {};
        this.initAllSliders();
    }

    /**
     * Destroys the swiper element.
     * @param sliderElement
     */
    static destroySwiper(sliderElement: HTMLElement): void {
        const id: string = sliderElement.id;
        if (EtSlider.sliders[id]) {
            EtSlider.sliders[id].destroy(true, true);
            delete EtSlider.sliders[id];
        }
    }

    /**
     * Returns the swiper element for the given id.
     * @param id
     */
    static getSwiperApi(id: string): Swiper | null {
        return EtSlider.sliders[id] || null;
    }

    /**
     * Returns all swiper elements.
     */
    static getSwiperApis() {
        return EtSlider.sliders;
    }

    // /**
    //  * Returns all swiper elements that were skipped.
    //  */
    // getSkippedSwipers(): HTMLElement[] {
    //     return this.skippedSwipers;
    // }
}
