import { defineStore } from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";

import type Artikel from "@/ts/components/Types/Artikel";

export const useArtikelStore = defineStore("artikelStore", () => {
    const artikel: Ref<Artikel> = ref({} as Artikel);
    const getArtikel: ComputedRef<Artikel> = computed(() => {
        return artikel.value;
    });
    const setArtikel: (newArtikel: Artikel) => void = (newArtikel: Artikel) => {
        artikel.value = newArtikel;
    };
    return { getArtikel, setArtikel };
});
