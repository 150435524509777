import {PageInitCallback} from "@/ts/components/Types/PageInitCallback";

/**
 * The EtailerFrontend class manages page initialization callbacks based on vwtype and vwname.
 */
export default class EtailerFrontend {
    private registeredPageInits: Map<string, Map<string, PageInitCallback>> = new Map();
    private currentViewType = "";
    private currentViewName = "";

    /**
     * Constructs an instance of EtailerFrontend.
     */
    constructor() {
        window.addEventListener("load", this.onDOMContentLoaded);
    }

    /**
     * Event handler for the DOMContentLoaded event.
     */
    private onDOMContentLoaded = (): void => {
        // Fetch basic information from html element using object destructuring
        const {vwtype = "", vwname = ""} = document.documentElement.dataset;
        this.currentViewType = vwtype;
        this.currentViewName = vwname;

        // Execute page inits
        this.invokePageInit("all", "all");
        this.invokePageInit(this.currentViewType, "all");
        this.invokePageInit(this.currentViewType, this.currentViewName);
    };

    /**
     * Invokes the registered page initialization callback for the specified vwtype and vwname.
     * @param vwtype - The vwtype.
     * @param vwname - The vwname.
     */
    private invokePageInit(vwtype: string, vwname: string): void {
        const pageInits: Map<string, PageInitCallback> = this.registeredPageInits.get(vwtype) || new Map();
        const callback: PageInitCallback | undefined = pageInits.get(vwname);
        if (callback) {
            callback(vwtype, vwname);
        }
    }

    /**
     * Registers a page initialization callback for the specified vwType and vwName.
     * @param vwType
     * @param vwName
     * @param callback - The page initialization callback.
     */
    public async registerPageInit(vwType: string, vwName: string, callback: PageInitCallback): Promise<void> {
        try {
            let pageInits: Map<string, PageInitCallback> | undefined = this.registeredPageInits.get(vwType);
            if (!pageInits) {
                pageInits = new Map();
                this.registeredPageInits.set(vwType, pageInits);
            }
            // Check if the callback has already been registered
            if (!pageInits.has(vwName)) {
                pageInits.set(vwName, (vwtype, vwname) => {
                    callback(vwtype, vwname);
                });
            }
        } catch (error) {
            console.error(`Error importing module for ${vwType}/${vwName}:`, error);
        }
    }

    /**
     * reads cookies
     * @param cname
     * @returns {*}
     */
    public getCookie(cname: string): string {
        const name = cname + "=";

        document.cookie.split(";").forEach(c => {
            c = c.trim();
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        });

        return "";
    }

    /**
     * set Cookie with defined parameters
     * @param cName
     * @param cValue
     * @param exDays
     */
    public setCookie(cName: string, cValue: string | number, exDays: number) {
        let s = cName + "=" + cValue;

        if (exDays > 0) {
            const d = new Date();
            d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
            s += "; expires=" + d.toUTCString();
        }

        document.cookie = s + "; path=/";
    }
}
