import EtSlider from "@/ts/components/EtSlider";
import FunctionHandler from "@/ts/components/Handlers/FunctionHandler";

export default class WarenkorbPreview {
    readonly #cartPreviewElement: HTMLElement;
    private observer: MutationObserver;
    private readonly debouncedInitSwiper: () => void;

    constructor(cartPreviewElement: HTMLElement) {
        if (!(cartPreviewElement instanceof HTMLElement)) {
            // console.error("cartPreviewElement is not an HTMLElement", cartPreviewElement);
            throw new Error("cartPreviewElement is not an HTMLElement");
        }

        this.debouncedInitSwiper = FunctionHandler.debounce(() => {
            this.initSwiper();
        }, 100);

        this.#cartPreviewElement = cartPreviewElement;
        this.observer = new MutationObserver(this.mutationHandler.bind(this));
        this.observer.observe(this.#cartPreviewElement, {attributes: false, childList: true, subtree: false});

        this.debouncedInitSwiper();
        this.#cartPreviewElement.addEventListener("click", (event) => {
            event.stopPropagation(); // nicht automatisch bei click schließen - das ist schlecht mit nem Slider
        });
    }

    mutationHandler(mutations: MutationRecord[], observer: MutationObserver) {
        this.debouncedInitSwiper();
    }

    initSwiper() {
        const swiperElement = this.#cartPreviewElement?.querySelector(".element_swiperjs");
        // console.debug("WarenkorbPreview.initSwiper", swiperElement);
        if (swiperElement instanceof HTMLElement) {
            new EtSlider(swiperElement);
        } else {
            this.#cartPreviewElement.querySelectorAll("[data-href]").forEach((el) => {
                (el as HTMLElement).style.cursor = "pointer";
                el.addEventListener("click", (e) => {
                    const link = (el as HTMLElement).dataset.href ?? "";
                    if (link !== "") {
                        window.location.href = link;
                    }
                });
            });

            const scroll_container = this.#cartPreviewElement.querySelector(".personal_warenkorb_preview__items") as HTMLElement;
            const button_scroll_up = this.#cartPreviewElement.querySelector(".personal_warenkorb_preview__items_up") as HTMLElement;
            const button_scroll_down = this.#cartPreviewElement.querySelector(".personal_warenkorb_preview__items_down") as HTMLElement;

            if (button_scroll_up && button_scroll_down) {
                button_scroll_down.addEventListener("click", (e) => {
                    // console.debug("scrollBy", scroll_container);
                    scroll_container.scrollBy({top: 110, behavior: "smooth"});
                });
                button_scroll_up.addEventListener("click", (e) => {
                    // console.debug("scrollBy", scroll_container);
                    scroll_container.scrollBy({top: -110, behavior: "smooth"});
                });
            }
        }
    }
}
