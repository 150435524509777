export default class DesktopMenu {
    desktopMenuElement: HTMLElement | null;
    desktopMenuElementSelector: string;
    menuIdCache: Set<string | number> = new Set();

    constructor(desktopMenuElementSelector: string, openMenuIds?: number[]) {
        const desktopMenuElement = document.querySelector(desktopMenuElementSelector);

        if (!(desktopMenuElement && desktopMenuElement instanceof HTMLElement)) {
            throw new Error('DesktopMenu: desktopMenuElement not found');
        }

        this.desktopMenuElement = desktopMenuElement;
        this.desktopMenuElementSelector = desktopMenuElementSelector;

        if (!(this.desktopMenuElement.dataset.menuinit === 'done')) {
            this.desktopMenuElement.dataset.menuinit = 'done';
            this.menuIdCache = new Set(openMenuIds);

            let menuFound = false;
            if (openMenuIds && Array.isArray(openMenuIds) && openMenuIds.length) {
                openMenuIds.every((mid) => {
                    menuFound = this.setMenuItemActive(mid);
                    return !menuFound;
                });
            }

            if (!menuFound) {
                this.setMenuItemActive(null, window.etData.vwTypeId + ':' + window.etData.vwName + ':' + window.etData.vwId);
            }
        }
    }

    private setMenuItemActive(mid?: number | null, mkey?: string | null, climbingUp: boolean = false): boolean {
        let result = false;
        let linkSelector = '';
        let foundElement: HTMLAnchorElement | null = null;

        if (typeof mid === 'number' && mid > 0) {
            linkSelector = `${this.desktopMenuElementSelector} *[data-mid="${mid}"]`;
            foundElement = document.querySelector(linkSelector);
        } else if (typeof mkey === 'string' && mkey.length) {
            linkSelector = `${this.desktopMenuElementSelector} *[data-mkey="${mkey}"]`;
            foundElement = document.querySelector(linkSelector);
        }
        // console.debug('DesktopMenu.setMenuItemActive', {mid, mkey, climbingUp, linkSelector, foundElement});

        if (foundElement) {
            if (!climbingUp) {
                foundElement.classList.add('current');
            }

            if (mid === null) {
                mid = Number(foundElement.dataset.mid);
            }

            if (typeof mid === 'number' && mid > 0) {
                if (this.menuIdCache.has(mid)) {
                    return false;
                }
                this.menuIdCache.add(mid);
            }

            if (foundElement.dataset.hasOwnProperty('pid')) {
                this.setMenuItemActive(Number(foundElement.dataset.pid), null, true);
            }

            result = true;
        }

        return result;
    }
}
