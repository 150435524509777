export default class MobileMenu {
    mobileMenu: HTMLElement | null;
    menuIdCache: Set<string | number> = new Set();

    constructor(mobileMenu?: HTMLElement, openMenuIds?: number[]) {
        this.mobileMenu = mobileMenu || document.querySelector(".mobile_menu");
        if (mobileMenu && mobileMenu instanceof HTMLElement) {
            if (mobileMenu.dataset.menuinit === 'done') {
                return;
            }
            mobileMenu.dataset.menuinit = 'done';

            this.menuIdCache = new Set(openMenuIds);
            this.initMobileMenu();

            let menuFound = false;
            if (openMenuIds && Array.isArray(openMenuIds) && openMenuIds.length) {
                menuFound = this.setMenuItemActive(openMenuIds[openMenuIds.length - 1]);
            }

            if (!menuFound) {
                this.setMenuItemActive(null, window.etData.vwTypeId + ':' + window.etData.vwName + ':' + window.etData.vwId);
            }
        }
    }

    private initMobileMenu() {
        this.mobileMenu?.querySelectorAll(".mobile_menu__tab_button").forEach((button) => {
            button.addEventListener("click", () => {
                this.activateTabPage(button.getAttribute("data-menu-target") || '');
            });
        });

        this.mobileMenu?.querySelectorAll("button.mobile_menu__l1_item").forEach((button) => {
            button.addEventListener("click", () => {
                if ((button as HTMLButtonElement).onclick) return;
                button.nextElementSibling?.classList.toggle("d-none");
            });
        });
    }

    private setMenuItemActive(mid?: number | null, mkey?: string | null, climbingUp: boolean = false): boolean {
        let result = false;
        let linkSelector = '';
        let linkElement: HTMLAnchorElement | null = null;

        if (typeof mid === 'number' && mid > 0) {
            linkSelector = `.mobile_menu [data-mid="${mid}"]`;
            linkElement = document.querySelector(linkSelector);
        } else if (typeof mkey === 'string' && mkey.length) {
            linkSelector = `.mobile_menu [data-mkey="${mkey}"]`;
            linkElement = document.querySelector(linkSelector);
        }
        // console.debug({mid, mkey, climbingUp, linkElement});

        if (linkElement) {
            if (!climbingUp) {
                linkElement.classList.add('mobile_menu__active_node');
                if (!climbingUp) {
                    // linkElement.classList.add('element_mobile_menu__link--current');
                }
                // find all parent elements with the class .mobile_menu__submenu and remove the class .d-none
                let tmpElement: HTMLElement = linkElement;
                while (tmpElement.parentElement) {
                    tmpElement = tmpElement.parentElement;

                    if (tmpElement.classList.contains('mobile_menu__submenu')) {
                        tmpElement.classList.remove('d-none');
                    }

                    if (tmpElement.classList.contains('mobile_menu__tab_page')) {
                        this.activateTabPage(tmpElement.id);
                        // stop parent processing
                        break;
                    }
                }
            }

            if (mid === null) {
                mid = Number(linkElement.dataset.mid);
            }

            if (typeof mid === 'number' && mid > 0) {
                if (this.menuIdCache.has(mid)) {
                    return false;
                }
                this.menuIdCache.add(mid);
            }

            if (linkElement.dataset.hasOwnProperty('pid')) {
                this.setMenuItemActive(Number(linkElement.dataset.pid), null, true);
            }

            result = true;
        }

        return result;
    }

    public activateTabPage(tabPageKey: string) {
        this.mobileMenu?.querySelectorAll(".mobile_menu__tab_button").forEach((item) => {
            item.classList.remove("mobile_menu__active_node");
            if (item.getAttribute("data-menu-target") === tabPageKey) {
                item.classList.add("mobile_menu__active_node");
            }
        });
        this.mobileMenu?.querySelectorAll(".mobile_menu__tab_page").forEach((item) => {
            item.classList.add("d-none");
            if (item.id === tabPageKey) {
                item.classList.remove("d-none");
            }
        });
    }
}