import type EtDataType from "@/ts/components/Types/EtDataType";
import {AnyType} from "@/ts/components/Types/AnyType";

/**
 * Funktionssammlung für die Verarbeitung von Funktionen.
 */
export default class FunctionHandler {
    private executing = false;
    private queue: Array<() => Promise<void>> = [];
    private static etData: EtDataType = window.etData;

    /**
     * Prevents concurrent execution of an asynchronous function.
     * @param func The asynchronous function to be executed without concurrency.
     * @param executeLastOnly Determines whether only the last enqueued function should be executed. Defaults to false.
     * @returns A function that can be used to call the func without allowing concurrent execution.
     */
    public preventConcurrentExecution(
        func: (...args: unknown[]) => Promise<void>,
        executeLastOnly = false
    ): (...args: unknown[]) => Promise<void> {
        return (...args: unknown[]): Promise<void> => {
            const promiseFunction = () => new Promise<void>((resolve, reject) => {
                func(...args)
                    .then(resolve)
                    .catch(reject);
            });

            if (executeLastOnly) {
                this.queue = [promiseFunction];
            } else {
                this.queue.push(promiseFunction);
            }

            if (!this.executing) {
                this.startExecution();
            }

            return Promise.resolve();
        };
    }

    /**
     * Starts executing functions in the queue asynchronously.
     * This method will continue executing functions until the queue is empty.
     * If an error occurs while executing a function, it will be thrown and the execution will stop.
     */
    private async startExecution(): Promise<void> {
        this.executing = true;

        while (this.queue.length > 0) {
            const nextFunction = this.queue.shift();

            if (nextFunction) {
                await nextFunction();
            }
        }

        this.executing = false;
    }


    /**
     * Creates a debounced version of a function that delays its execution until after a specified time has passed since the last invocation.
     *
     * @param func The function to debounce.
     * @param wait The number of milliseconds to wait before invoking the function after the last invocation.
     * @param immediate Specifies whether the function should be invoked immediately on the leading edge (true) or on the trailing edge (false).
     * @returns A debounced function that can be invoked.
     */
    public static debounce(func: (...args: AnyType[]) => void, wait = 100, immediate = false): (...args: AnyType[]) => void {
        let timeoutId: ReturnType<typeof setTimeout>;
        let immediateTimeoutId: ReturnType<typeof setTimeout> | null;

        return (...args: AnyType[]) => {
            clearTimeout(timeoutId);

            if (immediate && !immediateTimeoutId) {
                func.apply(this, args);
            }

            immediateTimeoutId = null;

            timeoutId = setTimeout(() => {
                if (!immediate) {
                    func.apply(this, args);
                }
            }, wait);

            if (immediate && !immediateTimeoutId) {
                immediateTimeoutId = setTimeout(() => {
                    immediateTimeoutId = null;
                }, wait);
            }
        };
    }

    /**
     * Waits for the given number of milliseconds.
     * @param milliseconds
     */
    public static async waitMilliseconds(milliseconds: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    /**
     * Throttles the execution of a function.
     *
     * @param givenFunction - The function to throttle.
     * @param threshold - The time threshold in milliseconds.
     * @param immediate - Specifies if the function should be called immediately on the leading edge.
     * @returns Function - The throttled function.
     */
    public static throttle(givenFunction: Function, threshold = 100, immediate = false) {
        let timeoutId: ReturnType<typeof setTimeout>;
        let lastExecutionTime = 0;
        return (...args: AnyType[]): void => {
            const currentTime: number = Date.now();
            const shouldCallNow: boolean = immediate && (currentTime - lastExecutionTime >= threshold);
            if (shouldCallNow) {
                lastExecutionTime = currentTime;
                givenFunction.apply(this, args);
            } else {
                clearTimeout(timeoutId);
                timeoutId = setTimeout((): void => {
                    lastExecutionTime = currentTime;
                    givenFunction.apply(this, args);
                }, threshold);
            }
        };
    }

    /**
     * Adds an event listener to the specified target element.
     *
     * @param {string} type - The type of the event to listen for (e.g., 'DOMContentLoaded', 'load').
     * @param {function} listener - The callback function to execute when the event occurs.
     * @param {object|boolean} [options] - An optional object that specifies event listener options (e.g., capture, once, passive).
     *                                    It can also be a boolean value indicating the capture option.
     */
    public static addEventListener(type: string, listener: AnyType, options?: { capture?: boolean, once?: boolean, passive?: boolean } | boolean): void {
        let eventTarget: EventTarget | null;
        if (type === "load") {
            eventTarget = window;
        } else {
            eventTarget = document;
        }
        if (eventTarget) {
            if (typeof options === "boolean" || typeof options === "object") {
                eventTarget.addEventListener(type, listener as EventListenerObject, options);
            } else {
                eventTarget.addEventListener(type, listener as EventListener);
            }
            if (this.etData.browserEventsFired.has(type) && typeof listener === "function") {
                listener.call(null, null);
            }
        }
    }

}
