import {defineStore} from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";
import type {EtPersonalJsonData} from "@/ts/components/Types/EtPersonalJsonData";
import EtPersonalJson from "@/ts/components/EtPersonalJson";

export const usePersonalJsonStore = defineStore("personalJsonStore", () => {
    const personalJson: Ref<EtPersonalJsonData> = ref({} as EtPersonalJsonData);
    const getPersonalJson: ComputedRef<EtPersonalJsonData> = computed(() => {
        if(!personalJson.value){
            EtPersonalJson.getInstance()
                .fetchData()?.then((result: EtPersonalJsonData | void) => {
                    if(!result) return;
                    setPersonalJson(result);
                });
        }
        return personalJson.value;
    });
    const setPersonalJson: (newPersonalJson: EtPersonalJsonData) => void = (newPersonalJson: EtPersonalJsonData) => {
        personalJson.value = newPersonalJson;
    };
    return { getPersonalJson, setPersonalJson };
});
