import EtConsole from "@/ts/components/EtConsole";
import ApiHandler from "@/ts/components/Handlers/ApiHandler";
import type EtDataType from "@/ts/components/Types/EtDataType";
import FrontendVueHandler from "@/ts/components/Handlers/FrontendVueHandler";
import StaticClass from "@/ts/components/StaticClass";
import {AnyType} from "@/ts/components/Types/AnyType";
import {getSpinnerHtml} from "@/ts/components/Spinner";
import {type Modal} from "bootstrap";

/**
 * Funktionssammlung für Frontend-Operationen.
 */
export default class FrontendHandler extends StaticClass {

    /**
     * Toggles the display of the given container ids.
     * @param containerIds
     * @param type
     */
    public static toggleContainerByIds(containerIds: Array<number | string>, type = "block"): void {
        containerIds.forEach((containerId: number | string): void => {
            const container: HTMLElement | null = document.getElementById(String(containerId));
            if (container) {
                container.style.display = container.style.display === "none" ? type : "none";
            }
        });
    }

    /**
     * Sets the public variables in the etData object of the window.
     *
     * @param {Record<string, any>} vars - The variables to set.
     * @return {void} This function does not return anything.
     */
    public static setPublicVars(vars: Record<string, AnyType>): void {
        if (!window.etData.publicVars) {
            window.etData.publicVars = {};
        }

        Object.assign(window.etData.publicVars, vars);
        // TODO: Warum werden hier die Funktionen an das window object gehängt?
        // Antwort: damit sie in den Templates aufgerufen werden können. Wir müssten überprüfen, ob wir das benötigen und sonst können wir eine private Liste machen.
        // TODO/Anm OS: Das ist ein Quickfix für Vanilla-JS [onclick]-Handler ... das muss sauber gelöst werden - ggf doch wieder über `et.app.function`. Bei der Gelegenheit gleich publicVars mit lösen.
        for (const key in vars) {
            if (typeof vars[key] === "function") {
                console.warn("FrontendHandler.setPublicVars - Function \"" + key + "\" is published to window scope");
                (window as Window)[key] = vars[key];
            }
        }
    }

    /**
     * Retrieves the value of a public variable by its name.
     *
     * @param {string} name - The name of the public variable.
     * @return {T} The value of the public variable.
     */
    public static getPublicVar<T>(name: string): T {
        if (!window.etData.publicVars) {
            window.etData.publicVars = {};
        }
        return window.etData.publicVars[name] || window[name];
    }

    /**
     * Gibt zurück, ob die Variante des Elements aktiv/sichtbar ist.
     * @param element
     */
    public static isVariantOfElementActive(element: HTMLElement): boolean {
        const variantElement: HTMLElement | null = element.closest(".vproduct");
        if (!variantElement) {
            return true;
        }
        return window.getComputedStyle(variantElement).display !== "none";
    }


    /**
     * Opens a modal with the given message and handles developer error message.
     *
     * @param message
     * @param error
     * @param fallbackMessage
     */
    public static showErrorMessage(
        message: string = "unknown error",
        error: Error = new Error(""),
        fallbackMessage: string = "unknown error"
    ): void {
        // Developer error message (evtl. im Backend speichern?)
        const etConsole: EtConsole = this.getPublicVar<EtConsole>("etConsole");
        etConsole.error((error.message !== "") ? error.message : fallbackMessage, error.stack ?? "");

        const modalBody: HTMLElement | null = document.querySelector("#page_modal [data-template-id=\"modal_body\"]");
        if (modalBody) {
            modalBody.innerHTML = "";

            // ERROR MESSAGE
            modalBody.classList.add("text-danger", "text-center");

            // Close Button
            const okButton: HTMLButtonElement = document.createElement("button");
            okButton.setAttribute("type", "button");
            okButton.classList.add("btn", "btn-danger", "mt-4");
            okButton.textContent = "OK";
            okButton.setAttribute("data-bs-dismiss", "modal");
            modalBody.appendChild(document.createElement("br"));
            modalBody.appendChild(okButton);

            // Modal einblenden
            const pageModalApi: Modal = this.getPublicVar<Modal>("pageModalApi");
            if (pageModalApi) {
                pageModalApi.show();
            }

            modalBody.innerHTML = (message || fallbackMessage) + modalBody.innerHTML;
        }
    }

    /**
     * Handles moving innerHTML content without directly manipulating the DOM.
     */
    public static moveInnerHtml(): void {
        const elementsByContentId: { [key: string]: Element[] } = {};
        const activeElementsByContentId: { [key: string]: Element | null } = {};
        const elements: NodeListOf<Element> = document.querySelectorAll("[data-content-id]");

        for (const element of Array.from(elements)) {
            const elementContentId: string | null = element.getAttribute("data-content-id");
            if (elementContentId) {
                elementsByContentId[elementContentId] = elementsByContentId[elementContentId] || [];
                elementsByContentId[elementContentId].push(element);
                if (element.classList.contains("content_active")) {
                    activeElementsByContentId[elementContentId] = element;
                }
            }
        }

        for (const contentId in elementsByContentId) {
            const activeElement: Element | null = activeElementsByContentId[contentId];
            if (activeElement) {
                const elementContent: string = activeElement.innerHTML;
                const contentElements: Element[] = elementsByContentId[contentId];
                for (const element of contentElements) {
                    const isElementVisible: boolean = getComputedStyle(element).display !== "none";
                    element.innerHTML = isElementVisible ? elementContent : "";
                    element.classList.toggle("content_active", isElementVisible);
                }
            }
        }
    }

    /**
     * Lädt und rendert ein Template in den angegebenen Div-Container (Angabe der data-template-id).
     * @param template
     * @param container Div mit data-template-id Attribut oder id
     * @param params Werden per POST an das Template übergeben und an das window object gehängt.
     * @param showLoading Show loading spinner
     * @param vwId
     * @param vwType
     * @param vwName
     * @param presenceId
     * @param addPageTypescript Add Typescript file /pages/${vwType}/${vwName}
     * @param predefinedUrl
     * @param artikelNr
     */
    public static async loadTemplate(
        template: string,
        container: string,
        params: { [name: string]: string } = {},
        showLoading = true,
        vwId: number = this.getPublicVar<EtDataType>("etData").vwId,
        vwType: string = this.getPublicVar<EtDataType>("etData").vwType,
        vwName: string = this.getPublicVar<EtDataType>("etData").vwName,
        presenceId: number = this.getPublicVar<EtDataType>("etData").presenceId,
        addPageTypescript = true,
        predefinedUrl: string = "",
        artikelNr: string = ""
    ): Promise<void> {
        const containerElement: HTMLDivElement | null = document.querySelector<HTMLDivElement>(
            `[data-template-id="${container}"]`
        ) ?? document.getElementById(container) as HTMLDivElement;
        if (!containerElement) {
            return;
        }

        containerElement.innerHTML = "";

        const loadingElement: HTMLDivElement = document.createElement("div");
        let loadingShown = false;

        if (showLoading) {
            loadingElement.innerHTML = "<div class=\"d-flex justify-content-center w-100\">"
                + getSpinnerHtml() + "</div>";
            containerElement.appendChild(loadingElement);
            loadingShown = true;
        }

        let url: string;
        if (predefinedUrl !== "") {
            url = predefinedUrl;
        } else {
            const urlParams: string[] = [];
            if (vwType) {
                urlParams.push(`vw_type=${encodeURIComponent(vwType)}`);
            }
            if (vwName) {
                urlParams.push(`vw_name=${encodeURIComponent(vwName)}`);
            }
            if (vwId) {
                urlParams.push(`vw_id=${vwId}`);
            }
            if (artikelNr) {
                urlParams.push(`artikelnr=${artikelNr}`);
            }

            url = `/index.php?${urlParams.join("&")}`;
        }

        const requestParams: {
            service: string;
            url: string;
            template: string;
            praesenz?: string;
        } = {
            service: "getViewContent",
            url: encodeURI(url),
            template: encodeURI(template),
        };

        if (presenceId) {
            requestParams.praesenz = presenceId.toString();
        }

        await this.blockSubmitAndShowLoading(
            async () => {
                ApiHandler.getJsonResponse(requestParams, params).then((response: any) => {
                    if (response && response.data !== undefined) {
                        containerElement.innerHTML = response.data;
                    } else {
                        containerElement.innerHTML = "";
                    }
                    if (params) {
                        this.setPublicVars(params);
                    }
                    return Promise.resolve();
                }).catch((error) => {
                    containerElement.innerHTML = "resource not available";
                    return Promise.reject(error);
                }).finally(() => {
                    if (loadingShown) {
                        loadingElement.remove();
                    }
                    if (addPageTypescript) {
                        import(`../../pages/${vwType}/${vwName}.ts`).then((file) => {
                            file.default(vwType, vwName);
                            FrontendVueHandler.autoRegisterVueElements();
                        });
                    }
                    containerElement.setAttribute("data-template-loaded", "true");
                });
            },
            containerElement
        );

        return Promise.resolve();
    }

    public static async blockSubmitAndShowLoading(
        func: () => Promise<AnyType>,
        elementsToShowSpinner: HTMLElement | HTMLElement[] | NodeListOf<HTMLElement>,
        submitButtons?: HTMLButtonElement | HTMLButtonElement[] | NodeListOf<HTMLButtonElement>
    ): Promise<void> {
        const toArray = (input: HTMLElement | HTMLElement[] | NodeListOf<HTMLElement>): HTMLElement[] => {
            if (Array.isArray(input)) return input;
            if (input instanceof NodeList) return Array.from(input);
            return [input];
        };

        const elementsArray: HTMLElement[] = toArray(elementsToShowSpinner);
        const buttonsArray: HTMLButtonElement[] = toArray(submitButtons as HTMLElement | NodeListOf<HTMLElement> | HTMLElement[]) as HTMLButtonElement[]; // A type assertion is added here to handle potential undefined values.

        if (buttonsArray) {
            buttonsArray.forEach(button => {
                if (!(button instanceof HTMLButtonElement)) return;
                button.disabled = true;
            });
        }

        const elementsToRemove: HTMLElement[] = [];
        if (elementsToShowSpinner == document.body) {
            showSpinner();
        } else {
            elementsArray.forEach(element => {
                if (!(element instanceof HTMLElement)) return;

                const overlay: HTMLDivElement = document.createElement("div");
                overlay.classList.add("spinner_loading_overlay");
                overlay.innerHTML = getSpinnerHtml();
                overlay.dataset.visible = "true";

                element.appendChild(overlay);
                elementsToRemove.push(overlay);
            });
        }

        const removeSpinner = () => {
            if (elementsToShowSpinner == document.body) {
                hideSpinner();
            } else {
                elementsToRemove.forEach(elementToRemove => elementToRemove.remove());
            }
            buttonsArray.forEach(button => {
                if (!(button instanceof HTMLButtonElement)) return;
                button.disabled = false;
            });
        };

        return new Promise<void>(async (resolve, reject) => {
            try {
                await func();
                removeSpinner();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }
}
