import { defineStore } from "pinia";
import {computed, ComputedRef, Ref, ref} from "vue";
import type Translation from "@/ts/components/Types/Translation";

export const useTranslationStore = defineStore("translationStore", () => {
    const translation: Ref<Translation> = ref({} as Translation);
    const getTranslation: ComputedRef<Translation> = computed(() => {
        return translation.value;
    });
    const setTranslation: (newTranslation: Translation) => void = (newTranslation: Translation) => {
        translation.value = newTranslation;
    };
    return { getTranslation, setTranslation };
});
