/**
 * Schreibt Vorhandene Lieferanschriften nach Auswahl ins Formular
 * & fügt LA-Löschen Action in mein Konto Bereich hinzu
 */
export default class LieferanschriftSelector {
    inputFields= [
        "titel",
        "name1",
        "vorname",
        "nachname",
        "name2",
        "strasse",
        "plz",
        "ort",
        "bundesland",
        "packstation",
        "postnummer",
        "packstation_nummer",
        "hausnummer",
    ];

    constructor(formElement?: HTMLFormElement) {
        if(formElement){
            this.initLieferanschrift(formElement);
        } else {
            document.querySelectorAll("form").forEach((formElement: HTMLFormElement) => {
                this.initLieferanschrift(formElement);
            });
        }
    }

    /**
     * EventListener setzen
     */
    private initLieferanschrift = (formElement: HTMLFormElement) => {
        const radioAnschrift0 = formElement.querySelector("#kz_la0");
        const radioAnschrift1 = formElement.querySelector("#kz_la1");

        if (radioAnschrift0 && radioAnschrift1) {
            radioAnschrift0.addEventListener("change", () => {
                formElement.querySelector("#custom-delivery-address")?.classList.toggle("d-none");
            });
            radioAnschrift1.addEventListener("change", () => {
                formElement.querySelector("#custom-delivery-address")?.classList.toggle("d-none");
            });
        }

        const checkboxPackstation = formElement.querySelector("#la_packstation");
        const packstationDiv = formElement.querySelector("#packstationDiv");
        const adressInputs = formElement.querySelector("#adressInputs");
        if (checkboxPackstation && packstationDiv && adressInputs) {
            checkboxPackstation.addEventListener("change", (event) => {
                const isChecked = event.target?.checked;
                if(isChecked) {
                    packstationDiv?.classList.remove("d-none");
                    adressInputs?.classList.add("d-none");
                } else {
                    packstationDiv?.classList.add("d-none");
                    adressInputs?.classList.remove("d-none");
                }
            });
        }

        const checkboxFirma = formElement.querySelector("#p_o");
        if (checkboxFirma) {
            checkboxFirma.addEventListener("change", (event) => {
                const isChecked = event.target?.checked;
                formElement.querySelectorAll(".firma").forEach((dataElement: Element) => {
                    if (dataElement instanceof HTMLElement) {
                        if(isChecked) {
                            dataElement.classList.remove("d-none");
                        } else {
                            dataElement.classList.add("d-none");
                        }
                    }
                });
            });
        }

        const checkboxFirma2 = formElement.querySelector("#la_o");
        if (checkboxFirma2) {
            checkboxFirma2.addEventListener("change", (event) => {
                const isChecked = event.target?.checked;
                formElement.querySelectorAll(".firma").forEach((dataElement: Element) => {
                    if (dataElement instanceof HTMLElement) {
                        if(isChecked) {
                            dataElement.classList.remove("d-none");
                        } else {
                            dataElement.classList.add("d-none");
                        }
                    }
                });
                const abweichendeAdresseFirmaDiv = formElement.querySelector("#abweichendeAdresseFirmaDiv");
                if(abweichendeAdresseFirmaDiv instanceof HTMLElement) {
                    if(isChecked) {
                        abweichendeAdresseFirmaDiv.classList.remove("d-none");
                    } else {
                        abweichendeAdresseFirmaDiv.classList.add("d-none");
                    }
                }
            });
        }


        const savedLaElement = formElement.querySelector("#la_saved_id") as HTMLSelectElement;
        if (savedLaElement) {
            savedLaElement.addEventListener("change", () => {
                this.setLieferanschrift(parseInt(savedLaElement.value), formElement);
            });
        }

        const adresseLoeschenElement = formElement.querySelector("#remove-delivery-address");
        adresseLoeschenElement?.addEventListener("click", () => {
            const laSavedIdElement =  formElement.querySelector("#la_saved_id") as HTMLSelectElement;
            if (laSavedIdElement) {
                const laSavedId = parseInt(laSavedIdElement.value) || 0;
                if (laSavedId > 0) {
                    const laModeElement = formElement.querySelector("#la_mode") as HTMLInputElement;
                    if (laModeElement && laModeElement.form) {
                        laModeElement.value = "1";
                        laModeElement.form.submit();
                    }
                }
            }
        });
    };

    /**
     * Die "Gespeicherte Lieferanschrift" auswählen (Wird dann in "Abweichende Lieferanschrift" übertragen)
     */
    private setLieferanschrift = (id: number, formElement: HTMLFormElement) => {
        this.resetLieferanschrift(formElement);

        if (id > 0) {
            const saved_address = _la[id];

            const laAnredeInput = formElement.querySelector(`input[name="la_anrede"][value="${saved_address.anrede}"]`) as HTMLInputElement;
            if (laAnredeInput) {
                laAnredeInput.checked = true;
            }

            // Toggle Packstation
            const packstation = formElement.querySelector("#la_packstation");
            if(packstation) {
                if (Number(_la[id]["packstation"]) === 1) {
                    packstation.checked = true;
                    packstation.dispatchEvent(new Event("change"));
                } else {
                    packstation.checked = false;
                    packstation.dispatchEvent(new Event("change"));
                }
            }

            // Toggle Firma
            const firma = formElement.querySelector("#la_o");
            if(firma) {
                if (_la[id]["name1"] !== "") {
                    firma.checked = true;
                    firma.dispatchEvent(new Event("change"));
                } else {
                    firma.checked = false;
                    firma.dispatchEvent(new Event("change"));
                }
            }

            // Setze Werte in die Felder
            this.inputFields.forEach((fieldName) => {
                const inputElement = formElement.querySelector("#la_"+fieldName) as HTMLInputElement;
                if (inputElement) {
                    inputElement.value = saved_address[fieldName];
                    inputElement.readOnly = false;
                }
            });

            const laHausnummerElement = formElement.querySelector("#la_hausnummer") as HTMLInputElement;
            if (laHausnummerElement) {
                laHausnummerElement.value = saved_address.hausnummer;
                laHausnummerElement.readOnly = false;
            } else {
                if (saved_address.packstation && !saved_address.hausnummer) {
                    saved_address.hausnummer = saved_address.strasse.substring(saved_address.strasse.lastIndexOf(" ")).trim();
                }
                const laHausnummerHelperElement = formElement.querySelector("#la_hausnummer_helper") as HTMLInputElement;
                if (laHausnummerHelperElement) {
                    laHausnummerHelperElement.value = saved_address.hausnummer;
                    laHausnummerHelperElement.readOnly = false;
                }
            }

            const laLkzElement = formElement.querySelector("#la_lkz") as HTMLSelectElement;
            if (laLkzElement) {
                laLkzElement.value = saved_address.lkz;
            }

            const kzSaveLaElement = formElement.querySelector("#kz_save_la") as HTMLInputElement;
            if (kzSaveLaElement) {
                kzSaveLaElement.value = "1";
            }

            const adresseLoeschenElement = formElement.querySelector("#remove-delivery-address");
            if (adresseLoeschenElement) {
                adresseLoeschenElement.classList.remove("d-none");
            }

            const laRechnungInput = formElement.querySelector(`#la_ranschrift_${saved_address.rechnung}`);
            if(laRechnungInput) laRechnungInput.checked = true;

        }
    };

    private resetLieferanschrift = (formElement: HTMLFormElement) => {

        // uncheck Packstation
        const packstation = formElement.querySelector("#la_packstation");
        if(packstation && packstation.checked) {
            packstation.checked = false;
            packstation.dispatchEvent(new Event("change"));
        }

        // uncheck Firma
        const firma = formElement.querySelector("#la_o");
        if(firma) {
            firma.checked = false;
            firma.dispatchEvent(new Event("change"));
        }

        // uncheck Anrede
        const laAnredeInputs = formElement.querySelectorAll("input[name=\"la_anrede\"]");
        laAnredeInputs.forEach((laAnredeInput) => {
            laAnredeInput.checked = false;
        });

        // das erste element auswählen
        const laRechnungInputs = formElement.querySelectorAll("input[name=\"la_ranschrift\"]");
        laRechnungInputs.forEach((laRechnungInput, index) => {
            laRechnungInput.checked = (index==0);
        });

        // Alle restlichen Werte leeren
        for (const fieldSelector of this.inputFields) {
            const fieldElement = formElement.querySelector("#la_"+fieldSelector) as HTMLInputElement | HTMLSelectElement | null;
            if (fieldElement) {
                if (fieldElement instanceof HTMLSelectElement) {
                    fieldElement.selectedIndex = 0;
                } else if (fieldElement instanceof HTMLInputElement) {
                    fieldElement.value = "";
                    fieldElement.readOnly = false;
                }
            }
        }
    };

}
